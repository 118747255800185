import { QueryKey, UseQueryOptions } from 'react-query';

import { ApiError } from './api';
import { DataFetcher } from './dataFetcher';

export interface ApiCommand<
  ApiErrorResponse,
  T,
  V extends unknown[],
  E extends unknown[]
> {
  dataFetcher: DataFetcher<ApiErrorResponse, T, V, E>;
  urlArgs?: V;
  fetcherArgs?: E;
  options?: Omit<
    UseQueryOptions<T, ApiError<ApiErrorResponse>, T, QueryKey>,
    'queryKey' | 'queryFn'
  >;
}

export function isApiCommand<
  ApiErrorResponse,
  T,
  V extends unknown[],
  E extends unknown[]
>(command: unknown): command is ApiCommand<ApiErrorResponse, T, V, E> {
  if (command && typeof command === 'object' && 'dataFetcher' in command) {
    return true;
  }
  return false;
}

export function asApiCommand<
  ApiErrorResponse,
  T,
  V extends unknown[],
  E extends unknown[]
>(
  apiCommand:
    | ApiCommand<ApiErrorResponse, T, V, E>
    | DataFetcher<ApiErrorResponse, T, V, E>
): ApiCommand<ApiErrorResponse, T, V, E> {
  if (isApiCommand<ApiErrorResponse, T, V, E>(apiCommand)) {
    return apiCommand;
  } else {
    return {
      dataFetcher: apiCommand,
    };
  }
}
