import ReadMore from './ReadMore';
import LinkToNewTab from '../../navigation/LinkToNewTab';
import ConsentCheckbox from './ConsentCheckbox';
import { FunctionComponent } from 'react';
import './consent.less';
import { useI18n } from '../../language/i18n';

const AnalyticsConsent: FunctionComponent<AnalyticsConsentProps> = ({
  consent,
  onChangeConsent,
}) => {
  const [translation] = useI18n();

  return (
    <article className="hw-grid hw-block--mb-medium-2">
      <ConsentCheckbox
        checked={consent}
        onChange={() => {
          onChangeConsent(!consent);
        }}
        label={translation.portal.consent.stats.heading}
      />
      <p className="hw-grid__item hw-four-fifths hw-text-small hw-block--mt-small-2">
        {translation.portal.consent.stats.desc}
      </p>
      <ReadMore>
        <p className="hw-grid__item hw-four-fifths hw-text-small hw-block--mt-small-2">
          {translation.portal.consent.stats.more_info_1}
        </p>
        <p className="hw-grid__item hw-four-fifths hw-text-small hw-block--mt-small-2">
          {translation.portal.consent.stats.more_info_2}
        </p>
        <div className="hw-grid__item hw-one-whole hw-text-small">
          <p className="hw-text-small hw-block--mt-small-2">
            {translation.portal.consent.shared_with}
          </p>
          <LinkToNewTab
            href="https://help.siteimprove.com/support/solutions/articles/80000863908-siteimprove-analytics-cookies"
            size="small"
          >
            Siteimprove
          </LinkToNewTab>
        </div>
      </ReadMore>
    </article>
  );
};

interface AnalyticsConsentProps {
  consent: boolean;
  onChangeConsent: (consent: boolean) => void;
}

export default AnalyticsConsent;
