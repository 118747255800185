import { Fragment, FunctionComponent } from 'react';
import { addClassesFromProps } from '../basicFunctions';

import { SuccessIcon } from '@hedwig/icons';
import { IconSize } from '@hedwig/icons';
import { useI18n } from '../language/i18n';

interface ProgressUpdateProps {
  extraClassName?: string;
  progressCondition: boolean;
  doneCondition: boolean;
  reset: () => void;
  resetAfter?: number;
}

const ProgressUpdate: FunctionComponent<ProgressUpdateProps> = ({
  extraClassName,
  progressCondition,
  doneCondition,
  reset,
  resetAfter = 2000,
}) => {
  const [translation] = useI18n();

  if (doneCondition) {
    setTimeout(reset, typeof resetAfter === 'number' ? resetAfter : undefined);
  }
  return (
    <Fragment>
      {progressCondition && (
        <span
          className={`action-progress-text${addClassesFromProps(
            extraClassName
          )}`}
        >
          <span className="action-text-image tiny-spinner" />
          {translation.common.saving}
        </span>
      )}
      {doneCondition && (
        <span
          className={`action-completed-text${addClassesFromProps(
            extraClassName
          )}`}
        >
          <SuccessIcon
            extraClassName="button-icon-with-text"
            size={IconSize.SMALL}
          />
          {translation.common.saved}
        </span>
      )}
      {!progressCondition && !doneCondition && (
        <span className={extraClassName ? extraClassName : undefined} />
      )}
    </Fragment>
  );
};

export default ProgressUpdate;
