import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { ApiError } from './api';
import { DataFetcher } from './dataFetcher';
import { ApiCommand, asApiCommand } from './apiCommand';

export enum ApiRequestStatus {
  IDLE = 'IDLE',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

export const isLoading = (status: ApiRequestStatus) =>
  [ApiRequestStatus.IDLE, ApiRequestStatus.PENDING].includes(status);

export type ApiRequest<T, ApiErrorResponse> =
  | {
      status: ApiRequestStatus.IDLE;
    }
  | {
      status: ApiRequestStatus.PENDING;
    }
  | {
      status: ApiRequestStatus.SUCCESS;
      result: T;
    }
  | {
      status: ApiRequestStatus.FAILURE;
      error: ApiError<ApiErrorResponse>;
    };

export function useApi<
  ApiErrorResponse,
  T,
  V extends unknown[],
  E extends unknown[]
>(
  apiCommand:
    | ApiCommand<ApiErrorResponse, T, V, E>
    | DataFetcher<ApiErrorResponse, T, V, E>
): {
  request: ApiRequest<T, ApiErrorResponse>;
  refetch: () => Promise<void>;
  isRefetching: boolean;
} {
  const {
    dataFetcher,
    urlArgs,
    fetcherArgs: extraArgs,
    options,
  } = asApiCommand(apiCommand);
  const { isLoading, isRefetching, error, data, refetch, isIdle, isSuccess } =
    useQuery<T, ApiError<ApiErrorResponse>>(
      [
        dataFetcher.name,
        dataFetcher.url(...(urlArgs ? urlArgs : ([] as unknown as V))),
        ...(extraArgs ? extraArgs : ([] as unknown as E)),
      ],
      () =>
        dataFetcher.execute({
          urlArgs: urlArgs ? urlArgs : ([] as unknown as V),
          fetcherArgs: extraArgs ? extraArgs : ([] as unknown as E),
        }),
      options
    );

  const request: ApiRequest<T, ApiErrorResponse> = useMemo(() => {
    return isIdle
      ? {
          status: ApiRequestStatus.IDLE,
        }
      : isLoading
      ? {
          status: ApiRequestStatus.PENDING,
        }
      : isSuccess
      ? {
          status: ApiRequestStatus.SUCCESS,
          result: data,
        }
      : {
          status: ApiRequestStatus.FAILURE,
          error: error
            ? error
            : { name: 'ApiError', message: 'Unknown ApiError' },
        };
  }, [isIdle, isLoading, isSuccess, data, error]);

  if (request.status === ApiRequestStatus.FAILURE) {
    dataFetcher.trackError(request.error);
  }

  return {
    request,
    isRefetching,
    refetch: async () => {
      await refetch();
    },
  };
}
