import { FunctionComponent } from 'react';
import { addClassesFromProps } from '../basicFunctions';

interface HeadingProps {
  headerText: string;
  extraClassName?: string;
  sizeClass?: 'hw-h1' | 'hw-h2' | 'hw-h3' | 'hw-text-title';
  dataTestid?: string;
}

export const HEADING_CONTENT_ID = 'headingcontent';

const Heading: FunctionComponent<HeadingProps> = ({
  headerText,
  extraClassName,
  sizeClass = 'hw-h1',
  dataTestid,
}) => {
  return (
    <h1
      id={HEADING_CONTENT_ID}
      tabIndex={-1}
      className={`${sizeClass} hw-color-primary-darker${addClassesFromProps(
        extraClassName
      )}`}
      data-testid={dataTestid}
      dangerouslySetInnerHTML={{ __html: headerText }}
    />
  );
};

export default Heading;
