import { useEffect, useState, FunctionComponent, useCallback } from 'react';

import AnalyticsConsent from './AnalyticsConsent';
import { useTracking } from '../Analytics';
import LinkToNewTab from '../../navigation/LinkToNewTab';
import {
  CloseButton,
  PrimaryButton,
  SecondaryButton,
  widths,
} from '@hedwig/buttons';
import CustomModal from '../../basics/Modal/CustomModal';
import Heading, { HEADING_CONTENT_ID } from '../../pageBuildingBlocks/Heading';
import { useI18n } from '../../language/i18n';
import { FooterType } from '../../footer/Footer';

import './consent.less';
import { linkToInternalSite } from '../../navigation/externalNavigation';
import { IconSet } from '@hedwig/icons';

const Consent: FunctionComponent<ConsentProps> = ({ footerType }) => {
  const {
    consent,
    saveConsentAndUpdateTracking,
    registerAnalyticsEvent,
    showConsentDialog,
    setShowConsentDialog,
    canShowCookieBanner,
    isConsentSaved,
  } = useTracking();
  const [analyticsConsent, setAnalyticsConsent] = useState(
    isConsentSaved ? consent.analytics : canShowCookieBanner ? true : false
  );
  const [marketingConsent, setMarketingConsent] = useState(
    isConsentSaved ? consent.marketing : false
  );
  const [translation] = useI18n();

  const resetConsent = useCallback(() => {
    setAnalyticsConsent(
      isConsentSaved ? consent.analytics : canShowCookieBanner ? true : false
    );
    setMarketingConsent(isConsentSaved ? consent.marketing : false);
  }, [isConsentSaved, consent, canShowCookieBanner]);

  useEffect(() => {
    if (showConsentDialog) {
      registerAnalyticsEvent({
        category: 'Tilpass cookies',
        action: 'Viser dialog',
      });
    }
  }, [showConsentDialog, registerAnalyticsEvent]);

  useEffect(() => {
    resetConsent();
  }, [resetConsent]);

  const open = () => setShowConsentDialog(true);
  const close = () => {
    resetConsent();
    setShowConsentDialog(false);
  };

  const updateAnalyticsConsent = (updatedAnalyticsConsent: boolean) => {
    setAnalyticsConsent(updatedAnalyticsConsent);
    setMarketingConsent(updatedAnalyticsConsent && marketingConsent);
  };

  const saveConsent = () => {
    const updatedConsent = {
      ...consent,
      analytics: analyticsConsent,
      marketing: marketingConsent,
    };

    if (!updatedConsent.analytics && !updatedConsent.marketing) {
      registerAnalyticsEvent({
        category: 'Tilpass cookies',
        action: 'Ja til nødvendig',
      });
    }

    saveConsentAndUpdateTracking(updatedConsent);
    close();

    if (updatedConsent.analytics && updatedConsent.marketing) {
      registerAnalyticsEvent({
        category: 'Tilpass cookies',
        action: 'Ja til alt',
      });
    } else if (updatedConsent.analytics) {
      registerAnalyticsEvent({
        category: 'Tilpass cookies',
        action: 'Ja til analytics',
      });
    }
  };

  return (
    <div>
      <button
        className="button--link hw-text-small hw-link footer-link hw-block--mb-small-2"
        onClick={open}
        type="button"
      >
        {translation.common.frontpage.private.footer.change_cookies}
      </button>
      <CustomModal
        isOpen={showConsentDialog}
        onClick={close}
        ariaLabelledby={HEADING_CONTENT_ID}
        ariaDescribedby="cookies-modal-description"
      >
        <CloseButton onClick={close} iconSet={IconSet.REGULAR} />
        <Heading
          sizeClass="hw-h2"
          headerText={translation.portal.consent.heading}
          extraClassName="hw-block--mb hw-block--mt"
        />
        <p
          id="cookies-modal-description"
          className="hw-block--mb-medium-2 hw-text-small"
        >
          {translation.portal.consent.intro_1}{' '}
          <LinkToNewTab
            href={
              footerType === FooterType.LOGGED_IN
                ? linkToInternalSite('/cookies/med-virksomhetskonto').href
                : linkToInternalSite('/cookies/uten-virksomhetskonto').href
            }
            size="small"
          >
            {translation.portal.consent.intro_link_text}
          </LinkToNewTab>{' '}
          {translation.portal.consent.intro_2}
        </p>
        <AnalyticsConsent
          consent={analyticsConsent}
          onChangeConsent={updateAnalyticsConsent}
        />
        <div className="text-right hw-block--mt">
          <SecondaryButton width={widths.responsive} onClick={close}>
            {translation.portal.consent.button.cancel}
          </SecondaryButton>
          <PrimaryButton
            width={widths.responsive}
            onClick={saveConsent}
            extraClassName="hw-block--mt-small-4"
          >
            {translation.portal.consent.button.save}
          </PrimaryButton>
        </div>
      </CustomModal>
    </div>
  );
};

interface ConsentProps {
  footerType: FooterType;
}

export default Consent;
