import { FunctionComponent } from 'react';
import PrimaryButton from './PrimaryButton';
import ProgressUpdate from '@common/basics/ProgressUpdate';
import './buttons.less';

interface ButtonWithProgress {
  enabled?: boolean;
  text: string;
  progressCondition: boolean;
  doneCondition: boolean;
  reset: () => void;
  children?: never;
}

const ButtonWithProgress: FunctionComponent<ButtonWithProgress> = ({
  enabled = true,
  text,
  progressCondition,
  doneCondition,
  reset,
}) => (
  <div className="button-with-progress">
    <PrimaryButton
      extraClassName="button-air"
      type="submit"
      disabled={!enabled}
    >
      {text}
    </PrimaryButton>
    <ProgressUpdate
      extraClassName={'spacer-top-2-blocks'}
      progressCondition={progressCondition}
      doneCondition={doneCondition}
      reset={reset}
    />
  </div>
);

export default ButtonWithProgress;
