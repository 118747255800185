import { DecoderFunction } from 'typescript-json-decoder';

import { ApiError, get } from './api';
import { trackError } from '../errorHandling/errorTracking';

type Execute<T, V, E> = ({
  urlArgs,
  fetcherArgs,
}: {
  urlArgs: V;
  fetcherArgs: E;
}) => Promise<T>;

export interface DataFetcher<
  ApiErrorResponse,
  T,
  V extends unknown[],
  E extends unknown[]
> {
  execute: Execute<T, V, E>;
  url: (...args: V) => string;
  name: string;
  trackError: (error: ApiError<ApiErrorResponse>) => void;
}

type CommandWithApiCaller<
  ApiErrorResponse,
  T,
  V extends unknown[],
  E extends unknown[]
> = {
  apiCaller: (url: string, ...args: E) => Promise<T>;
  url: (...args: V) => string;
  name: string;
  trackError?: (error: ApiError<ApiErrorResponse>) => void;
};

function isCommandWithApiCaller<
  ApiErrorResponse,
  T,
  V extends unknown[],
  E extends unknown[]
>(
  command: Command<ApiErrorResponse, T, V, E>
): command is CommandWithApiCaller<ApiErrorResponse, T, V, E> {
  if ('apiCaller' in command) {
    return true;
  }
  return false;
}

type CommandWithDecoder<ApiErrorResponse, T, V extends unknown[]> = {
  apiErrorDecoder: DecoderFunction<ApiErrorResponse>;
  decoder: DecoderFunction<T>;
  url: (...args: V) => string;
  timeout?: number;
  name: string;
  trackError?: (error: ApiError<ApiErrorResponse>) => void;
};

type Command<ApiErrorResponse, T, V extends unknown[], E extends unknown[]> =
  | CommandWithApiCaller<ApiErrorResponse, T, V, E>
  | CommandWithDecoder<ApiErrorResponse, T, V>;

function createGenericErrorTracker<ApiErrorResponse>(name: string) {
  return (error: ApiError<ApiErrorResponse>) => {
    trackError(
      new Error(
        `DataFetcher '${name}' failed with error message '${error.message}'`,
        { cause: error }
      )
    );
  };
}

export const createDataFetcher = <
  ApiErrorResponse,
  T,
  V extends unknown[],
  E extends unknown[]
>(
  command: Command<ApiErrorResponse, T, V, E>
): DataFetcher<ApiErrorResponse, T, V, E> => {
  const trackError = command.trackError
    ? command.trackError
    : createGenericErrorTracker(command.name);

  if (isCommandWithApiCaller(command)) {
    return {
      execute: ({ urlArgs, fetcherArgs }) =>
        command.apiCaller(command.url(...urlArgs), ...fetcherArgs),
      url: command.url,
      name: command.name,
      trackError,
    };
  } else {
    return {
      execute: ({ urlArgs, fetcherArgs: _ }) =>
        get(
          command.apiErrorDecoder,
          command.url(...urlArgs),
          command.decoder,
          command.timeout
        ),
      url: command.url,
      name: command.name,
      trackError,
    };
  }
};
