interface IncidentsData {
  incidents: {
    name: string;
    shortLink: string;
    components: { id: string; status: string }[];
  }[];
}

interface ScheduledMaintenancesData {
  scheduled_maintenances: {
    name: string;
    shortLink: string;
    scheduled_for: string | Date;
    scheduled_until: string | Date;
    components: { id: string }[];
  }[];
}

export interface StatusPagePage {
  incidents: (arg: {
    filter?: string;
    success: (data: IncidentsData) => void;
    error: (err: Error) => void;
  }) => void;
  scheduled_maintenances: (arg: {
    filter?: string;
    success: (data: ScheduledMaintenancesData) => void;
    error: (err: Error) => void;
  }) => void;
}

export interface StatusPage {
  page: {
    new (arg: { page: string }): StatusPagePage;
  };
}

const statusPage = (function () {
  let statuspage: StatusPagePage;
  return () => {
    return statuspage
      ? statuspage
      : new window.StatusPage.page({ page: 'kbkxsqvvqxsn' });
  };
})();

export default statusPage;
