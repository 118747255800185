interface Config {
  googleMarketingTrackingID?: string;
  isDevModeEnabled?: boolean;
  enableScroll?: boolean;
  enableExternalNavigation?: boolean;
  registerOrganizationUrl?: string;
  sentry?: {
    enabled: boolean;
    environment?: string;
    dsn?: string;
  };
  session?: {
    idleTimeout: number;
    sessionTimeout: number;
    refreshInterval: number;
  };
}

const localConfig = {
  isDevModeEnabled: true,
  googleMarketingTrackingID: 'AW-802146354',
  enableScroll: true,
  enableExternalNavigation: true,
  registerOrganizationUrl:
    'https://localhost:9090/registrering/bedrift/login?redirect-url-signering=https%3A%2F%2Flocalhost%3A9000%2Fvirksomhet%2Flogginn%2Fprivat%3Fsource%3Dfullf%C3%B8rt-registrering',
};

const config: Config =
  (window as { signeringConfig?: Config }).signeringConfig || localConfig;

function valueOrDefault<T>(defaultValue: T, value?: T) {
  return value === undefined ? defaultValue : value;
}

const resolvedConfig = {
  isDevModeEnabled: valueOrDefault(false, config.isDevModeEnabled),
  googleMarketingTrackingID: valueOrDefault(
    '',
    config.googleMarketingTrackingID
  ),
  enableScroll: valueOrDefault(true, config.enableScroll),
  enableExternalNavigation: valueOrDefault(
    true,
    config.enableExternalNavigation
  ),
  registerOrganizationUrl: valueOrDefault(
    'https://www.digipost.no/app/registrering/bedrift/login?redirect-url-signering=https%3A%2F%2Flocalhost%3A9000%2Fvirksomhet%2Flogginn%2Fprivat%3Fsource%3Dfullf%C3%B8rt-registrering',
    config.registerOrganizationUrl
  ),
  sentry: valueOrDefault({ enabled: false }, config.sentry),
  session: valueOrDefault(
    {
      idleTimeout: 1000 * 60,
      sessionTimeout: 1000 * 60 * 30,
      refreshInterval: 1000 * 60 * 5,
    },
    config.session
  ),
};

// Overstyr verdier for kjøring av tester (altså ikke miljøet test.signering.posten.no)
if (process.env.NODE_ENV === 'test') {
  resolvedConfig.enableScroll = false;
  resolvedConfig.enableExternalNavigation = false;
}

export default resolvedConfig;
