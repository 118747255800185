import {
  FunctionComponent,
  MouseEvent,
  MouseEventHandler,
  PropsWithChildren,
  useCallback,
} from 'react';

import { addClassesFromProps } from '@common/basicFunctions';
import {
  Size,
  sizes,
  ButtonStyle,
  buttonStyles,
  Width,
  widths,
} from './buttonAttributes';

import './buttons.less';

type ButtonType = 'button' | 'submit';

interface BaseButtonProps {
  type?: ButtonType;
  id?: string;
  dataTestid?: string;
  disabled?: boolean;
  extraClassName?: string;
  size?: Size;
  width?: Width;
  ariaLabel?: string;
  ariaLabelledBy?: string;
}

interface WithButtonStyle {
  buttonStyle?: ButtonStyle;
}

interface WithSubmit {
  type: 'submit';
}

interface WithOnClick {
  type?: 'button';
  onClick: MouseEventHandler<HTMLButtonElement>;
}

export type WithButtonType = WithSubmit | WithOnClick;

export type ButtonProps = BaseButtonProps & WithButtonType & PropsWithChildren;

const Button: FunctionComponent<ButtonProps & WithButtonStyle> = ({
  id,
  dataTestid,
  children,
  disabled,
  extraClassName,
  size,
  width,
  ariaLabel,
  ariaLabelledBy,
  buttonStyle = buttonStyles.primary,
  ...buttonTypeProps
}) => {
  const getButtonWidthClass = () => {
    switch (width) {
      case widths.normal:
        return ' button--normal-width';
      case widths.wide:
        return ' button--wide-width hw-button--mobile-full';
      case widths.full:
        return ' hw-button--full';
      case widths.responsive:
        return ' hw-button--mobile-full';
      default:
        return '';
    }
  };

  const getButtonSizeClass = () => {
    switch (size) {
      case sizes.small:
        return ' hw-button--small';
      case sizes.medium:
        return ' hw-button--medium';
      case sizes.large:
        return ' hw-button--large';
      default:
        return '';
    }
  };

  const getButtonTypeClass = () => {
    switch (buttonStyle) {
      case buttonStyles.primary:
        return ' hw-button--primary';
      case buttonStyles.secondary:
        return ' hw-button--secondary';
      case buttonStyles['primary-outlined']:
        return ' hw-button--outline-primary';
      case buttonStyles['secondary-outlined']:
        return ' hw-button--outline-secondary';
      case buttonStyles.transparent:
        return ' button-transparent';
      default:
        return '';
    }
  };

  const getClassNameString = () => {
    return `hw-button${getButtonTypeClass()}${getButtonSizeClass()}${getButtonWidthClass()}${addClassesFromProps(
      extraClassName
    )}`;
  };

  const onClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      if (disabled) {
        return;
      }

      switch (buttonTypeProps.type) {
        case 'submit':
          return;
        default:
          buttonTypeProps.onClick(e);
      }
    },
    [disabled, buttonTypeProps]
  );

  return (
    <button
      type={buttonTypeProps.type || 'button'}
      id={id}
      data-testid={dataTestid}
      className={getClassNameString()}
      onClick={onClick}
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledBy}
      aria-disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
