import { OrganizationId } from './currentOrganization/organizationId';

export const API = 'api';
export const PORTAL = 'portal';
export const LOGGED_IN_HOME_PAGE_URL = 'velg';
export const JOBS_URL = 'oppdrag';
export const EDIT_ORGANIZATION_URL = 'rediger-virksomhet';
export const MY_ACCOUNT_URL = 'min-konto';
export const TRUSTEE_URL = 'forvaltere';
export const ACCOUNT_INFO_URL = 'kontoinfo';
export const ACCOUNT_DELETE_URL = 'slett-konto';
export const JOB_TAB_WAITING_URL = 'venter';
export const JOB_TAB_STOPPED_URL = 'stoppet';
export const JOB_TAB_API_URL = 'api';
export const JOB_TAB_SIGNED_URL = 'signert';
export const JOB_TAB_SEARCH_URL = 'sok';
export const CREATE_JOB_URL = 'opprett';
export const ONBOARDING_GET_STARTED = 'kom-i-gang';
export const ONBOARDING_ARCHIVE = 'lagring';
export const ONBOARDING_MORE_INFO = 'mer-info';
export const signatureJobsUrl = ({
  tabUrl,
  orgId,
}: {
  tabUrl: string;
  orgId: OrganizationId;
}): string => `/${orgId}/${JOBS_URL}/${tabUrl}`;

export const FRONTPAGE_CLICK = 'Forside-klikk';
export const API_TAB_ACTION = 'Avsender viser API-fanen';
export const API_TAB_LABEL = 'Avsender viste API-fane';
export const CHANGED_SEARCH_ACTION = 'Endret søk';
export const CHANGED_DATO_LABEL = 'Endret datointervall';
export const CHANGE_DOCUMENT_OWNER_LABEL = 'Endret dokumenteier';
export const ACTIVATE_WEB_FILTER_LABEL = 'Aktiverte Web-filter';
export const DEACTIVATE_WEB_FILTER_LABEL = 'Fjernet Web-filter';
export const ACTIVATE_API_FILTER_LABEL = 'Aktiverte API-filter';
export const DEACTIVATE_API_FILTER_LABEL = 'Fjernet API-filter';
export const SEARCH_FILTER_LABEL = 'Searched with filter';

export enum OppslagsTjenesteStatus {
  NOT_CHECKED = 'NOT_CHECKED',
  CHECK_FAILED = 'CHECK_FAILED',
  CHECKED = 'CHECKED_EXISTS',
}

export enum IdentifierInSignedDocument {
  NAME_AND_PERSONAL_IDENTIFICATION_NUMBER = 'NAME_AND_PERSONAL_IDENTIFICATION_NUMBER',
  NAME_AND_DATE_OF_BIRTH = 'NAME_AND_DATE_OF_BIRTH',
  NAME = 'NAME',
}

export enum NotificationSetting {
  EMAIL = 'EMAIL',
  EMAIL_AND_SMS = 'EMAIL_AND_SMS',
}

export enum ActivationSetting {
  IMMEDIATELY = 'IMMEDIATELY',
  LATER = 'LATER',
}

export enum ExpirationSetting {
  THREE_DAYS = 'THREE_DAYS',
  ONE_WEEK = 'ONE_WEEK',
  TWO_WEEKS = 'TWO_WEEKS',
  THIRTY_DAYS = 'THIRTY_DAYS',
  OTHER = 'OTHER',
}
