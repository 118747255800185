export type Size = 'small' | 'medium' | 'large';
type Sizes = { [key in Size]: Size };

export const sizes: Sizes = {
  small: 'small',
  medium: 'medium',
  large: 'large',
};

export type Width = 'normal' | 'wide' | 'full' | 'responsive';
type Widths = { [key in Width]: Width };

export const widths: Widths = {
  normal: 'normal',
  wide: 'wide',
  full: 'full',
  responsive: 'responsive',
};

export type ButtonStyle =
  | 'primary'
  | 'secondary'
  | 'primary-outlined'
  | 'secondary-outlined'
  | 'transparent';
type ButtonStyles = { [key in ButtonStyle]: ButtonStyle };

export const buttonStyles: ButtonStyles = {
  primary: 'primary',
  secondary: 'secondary',
  'primary-outlined': 'primary-outlined',
  'secondary-outlined': 'secondary-outlined',
  transparent: 'transparent',
};
