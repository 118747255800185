import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTracking } from './Analytics';
import { subsituteIdsWithPlacedholders } from '../navigation/urlSubstitutions';

const ensureTrailingSlash = (path: string) =>
  path.slice(-1) === '/' ? path : `${path}/`;

const ensurePrefix = (prefix: string, path: string) =>
  prefix ? `/${prefix}${path}` : path;

export const preparePath = (path: string) => {
  const preparedPath = subsituteIdsWithPlacedholders(path);

  return ensureTrailingSlash(preparedPath);
};

const createSiteImproveDynamicUrl = (
  origin: string,
  pathname: string,
  queryParams?: string
) => {
  return new URL(
    origin +
      '/dynamic' +
      preparePath(pathname) +
      (queryParams ? queryParams : '')
  );
};

const getRef = () => {
  if (!document.referrer) {
    return;
  }
  const ref = new URL(document.referrer);
  if (
    ref.hostname.includes('signering.posten.no') ||
    ref.hostname.includes('localhost')
  ) {
    return createSiteImproveDynamicUrl(ref.origin, preparePath(ref.pathname));
  } else {
    return ref;
  }
};

export const usePageviewTracker = (prefix: string) => {
  const location = useLocation();
  const { registerPageview, consent, isConsentSaved } = useTracking();
  const [lastTrackedPage, setLastTrackedPage] = useState<URL>();

  useEffect(() => {
    let path = preparePath(location.pathname);
    path = ensurePrefix(prefix, path);

    const newPage = createSiteImproveDynamicUrl(
      window.location.origin,
      path,
      location.search
    );
    if (
      isConsentSaved &&
      consent.analytics &&
      newPage.href !== lastTrackedPage?.href
    ) {
      registerPageview(newPage, lastTrackedPage ? lastTrackedPage : getRef());
      setLastTrackedPage(newPage);
    }
  }, [
    location,
    prefix,
    lastTrackedPage,
    setLastTrackedPage,
    registerPageview,
    consent.analytics,
    isConsentSaved,
  ]);
};

const PageviewTracker = ({ prefix }: { prefix: string }) => {
  usePageviewTracker(prefix);

  return null;
};

export default PageviewTracker;
