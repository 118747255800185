import * as Sentry from '@sentry/react';
import config from '../config';

// Error response code to ignore when tracking errors to Sentry
export const errorWhitelist = [
  'SIGNATURE_JOB_BLOCKED',
  'WRONG_ACCESS_CODE',
  'UNEXPECTED_SERVER_ERROR',
  'PASSWORD_PROTECTED',
  'SIGNER_NEEDS_ACCESS',
  'NOT_FORWARDED_TO_SIGNERS_DIGITAL_MAILBOX',
  'DUPLICATE_TRUSTEE',
];

export const enableErrorTracking = ({ appName }: { appName: string }) => {
  if (config.sentry.enabled) {
    Sentry.init({
      dsn: config.sentry.dsn,
      environment: config.sentry.environment,
      release: __VERSION__,
      ignoreErrors: [
        'Failed to fetch',
        'Load failed',
        'avbrutt',
        'cancelled',
        'Request timeout',
        'Mistet nettverksforbindelsen',
        'NetworkError when attempting to fetch resource',
        'The user aborted a request',
        'Fetch is aborted',
      ],
    });

    Sentry.setTag('app', appName);
  } else {
    console.log('Error tracking with Sentry is not enabled.');
  }
};

export const trackMessage = (message: string) => {
  if (config.sentry.enabled) {
    Sentry.captureMessage(message);
  }
};

export const trackBreadcrumb = (message: string) => {
  if (config.sentry.enabled) {
    Sentry.addBreadcrumb({ message });
  }
};

export const trackError = (error: Error) => {
  if (config.sentry.enabled) {
    Sentry.captureException(error);
  }
};
