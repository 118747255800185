import OnClickLanguageButton from './OnClickLanguageButton';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  IconColor,
  IconSize,
} from '@hedwig/icons';
import { FunctionComponent, useState, useRef } from 'react';
import { addClassesFromProps } from '../basicFunctions';
import { useI18n } from './i18n';
import './language.less';
import { useOutsideClick } from '../../landingPage/HeaderDropDown';

const ChangeLanguage: FunctionComponent<ChangeLanguageProps> = ({
  extraClassName,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [translation] = useI18n();
  const node = useRef<HTMLDivElement>(null);
  useOutsideClick(node, setExpanded);

  return (
    <div
      className={`change-language-wrapper hide--mobile-and-tablet${addClassesFromProps(
        extraClassName
      )}`}
      ref={node}
    >
      <button
        className="change-language-button button-transparent"
        aria-expanded={expanded}
        aria-controls="change-language-dropdown"
        onClick={(e) => {
          e.preventDefault();
          setExpanded(!expanded);
        }}
        type="button"
      >
        {expanded ? (
          <ChevronDownIcon
            size={IconSize.SMALL}
            extraClassName="language-icon icon-with-text"
            color={IconColor.WHITE}
          />
        ) : (
          <ChevronUpIcon
            size={IconSize.SMALL}
            extraClassName="language-icon icon-with-text"
            color={IconColor.WHITE}
          />
        )}
        {translation.common.language}
      </button>
      <div
        id="change-language-dropdown"
        className="change-language-dropdown-wrapper"
        aria-live="polite"
      >
        {expanded && (
          <div className="change-language-dropdown">
            <OnClickLanguageButton callback={() => setExpanded(false)} />
          </div>
        )}
      </div>
    </div>
  );
};

interface ChangeLanguageProps {
  extraClassName?: string;
}

export default ChangeLanguage;
