import {
  createContext,
  FunctionComponent,
  PropsWithChildren,
  useContext,
  useState,
} from 'react';

import SkipToMain from '../SkipToMain/SkipToMain';
import postenSigneringLogo from '@images/logos/posten-and-signering-logos.svg';
import postenSigneringLogoSmall from '@images/logos/posten-signering-mobil.svg';

import '@less/navbar.less';

interface PostenHeaderProps {
  homeLink?: string;
  inSigningProcess?: boolean;
}

const HamburgerContext = createContext({
  setHamburgerVisibility: (_: boolean) => {
    return;
  },
  isHamburgerVisible: false,
});

export const useHamburgerContext: () => {
  setHamburgerVisibility: (value: boolean) => void;
  isHamburgerVisible: boolean;
} = () => {
  const { isHamburgerVisible, setHamburgerVisibility } = useContext<{
    setHamburgerVisibility: (hamburgerVisibility: boolean) => void;
    isHamburgerVisible: boolean;
  }>(HamburgerContext);
  return { isHamburgerVisible, setHamburgerVisibility };
};

const PostenHeader: FunctionComponent<
  PostenHeaderProps & PropsWithChildren
> = ({ children, homeLink, inSigningProcess = false }) => {
  const [hamburgerVisibility, setHamburgerVisibility] = useState(false);

  return (
    <HamburgerContext.Provider
      value={{
        isHamburgerVisible: hamburgerVisibility,
        setHamburgerVisibility,
      }}
    >
      <div>
        <header
          className={`hw-navbar${inSigningProcess ? ' navbar--slim' : ''}`}
        >
          <SkipToMain />
          <nav
            className={`hw-flex--gutter-largest-desktop hw-navbar__content hw-navbar__top navbar${
              hamburgerVisibility ? ' navbar--fixed' : ''
            }`}
            aria-label="Header"
          >
            {homeLink ? (
              <>
                <a
                  href={homeLink}
                  className={`${
                    inSigningProcess
                      ? 'hide--mobile-and-tablet'
                      : 'hide--mobile'
                  }`}
                  data-testid="navbar-logo"
                >
                  <img
                    src={postenSigneringLogo}
                    alt="Posten Signering"
                    className="navbar__logo--large"
                  />
                </a>
                <a
                  href={homeLink}
                  className={`${
                    inSigningProcess
                      ? 'hide--small-desktop'
                      : 'hide--tablet-and-up'
                  }`}
                >
                  <img
                    src={postenSigneringLogoSmall}
                    alt="Posten Signering"
                    className="navbar__logo--small hw-block--mt-small-4"
                  />
                </a>
              </>
            ) : (
              <>
                <div
                  className={`${
                    inSigningProcess
                      ? 'hide--mobile-and-tablet'
                      : 'hide--mobile'
                  }`}
                >
                  <img
                    src={postenSigneringLogo}
                    alt="Posten Signering"
                    className="navbar__logo--large"
                  />
                </div>
                <div
                  className={`${
                    inSigningProcess
                      ? 'hide--small-desktop'
                      : 'hide--tablet-and-up'
                  }`}
                >
                  <img
                    src={postenSigneringLogoSmall}
                    alt="Posten Signering"
                    className="navbar__logo--small hw-block--mt-small-4"
                  />
                </div>
              </>
            )}
            {children}
          </nav>
        </header>
      </div>
    </HamburgerContext.Provider>
  );
};

export default PostenHeader;
