import { FunctionComponent, PropsWithChildren } from 'react';
import Modal from 'react-modal';

import './modal.less';

const CustomModal: FunctionComponent<CustomModalProps & PropsWithChildren> = ({
  width = '50%',
  padding = '2em',
  ariaLabelledby,
  ariaDescribedby,
  onClick,
  isOpen,
  children,
}) => {
  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(102, 102, 102, 0.75)',
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      width: width,
      padding: padding,
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      border: '0px',
      borderRadius: '0px',
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      onRequestClose={onClick}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      aria={{
        labelledby: ariaLabelledby,
        describedby: ariaDescribedby,
      }}
      appElement={document.getElementById('react')}
    >
      {children}
    </Modal>
  );
};

interface CustomModalProps {
  width?: string;
  padding?: string;
  ariaLabelledby: string;
  ariaDescribedby: string;
  onClick: (e: Event) => void;
  isOpen: boolean;
}

export default CustomModal;
