import { FunctionComponent } from 'react';
import ChangeLanguage from '../language/ChangeLanguage';
import './footer.less';
import { useI18n, withParams } from '../language/i18n';
import Consent from '../analytics/consent/Consent';
import postenLogo from '@images/logos/posten-logo-red.svg';
import { linkToInternalSite } from '../navigation/externalNavigation';

export const year = new Date().getFullYear();

const Footer: FunctionComponent<FooterProps> = ({
  footerType,
  inSigningProcess,
}) => {
  const [translation] = useI18n();
  return (
    <footer
      className={`${
        inSigningProcess ? ' footer__document-preview' : 'footer'
      } hw-footer hw-text-center hw-block--pt hw-block--pb`}
    >
      {!inSigningProcess && (
        <div className="footer-content">
          <p className="footer-text hide--mobile-and-tablet">
            {translation.common.footer.text}
          </p>
          <img
            src={postenLogo}
            alt="Posten"
            className="svg-posten-logo-big"
            aria-hidden="true"
          />
        </div>
      )}
      <div className="hw-block--pt hw-flex footer-bottom-row">
        <div className="hw-text-small hw-copyright footer-copyright">
          <span>
            {withParams(translation.common.footer.copyright_notice, { year })}
          </span>
        </div>

        {footerType !== FooterType.ADMIN && (
          <>
            <nav aria-label="Footer" className="hw-flex ui-max-new">
              <div>
                <a
                  href={
                    linkToInternalSite(
                      footerType === FooterType.LOGGED_IN
                        ? '/cookies/med-virksomhetskonto'
                        : '/cookies/uten-virksomhetskonto'
                    ).href
                  }
                  className="hw-link"
                >
                  {translation.common.footer.cookies}
                </a>
              </div>
              <Consent footerType={footerType} />
              <div>
                <a
                  href={
                    linkToInternalSite(
                      footerType === FooterType.LOGGED_IN
                        ? '/personvern/med-virksomhetskonto'
                        : '/personvern/uten-virksomhetskonto'
                    ).href
                  }
                  className="hw-link"
                >
                  {translation.common.footer.policies}
                </a>
              </div>
              <div>
                <a
                  href={
                    linkToInternalSite(
                      footerType === FooterType.LOGGED_IN
                        ? '/vilkar/med-virksomhetskonto'
                        : '/vilkar/uten-virksomhetskonto'
                    ).href
                  }
                  className="hw-link"
                >
                  {translation.common.frontpage.private.footer.terms}
                </a>
              </div>
            </nav>

            <nav aria-label="Footer" className="footer-nav ui-minmed-new">
              <div className="hw-block--mt-small-4 footer-link--group">
                <a
                  href={
                    linkToInternalSite(
                      footerType === FooterType.LOGGED_IN
                        ? '/cookies/med-virksomhetskonto'
                        : '/cookies/uten-virksomhetskonto'
                    ).href
                  }
                  className="hw-link footer-link footer-link--first"
                >
                  {translation.common.footer.cookies}
                </a>
                <Consent footerType={footerType} />
              </div>
              <div className="footer-link--group">
                <a
                  href={
                    linkToInternalSite(
                      footerType === FooterType.LOGGED_IN
                        ? '/personvern/med-virksomhetskonto'
                        : '/personvern/uten-virksomhetskonto'
                    ).href
                  }
                  className="hw-link footer-link footer-link--first"
                >
                  {translation.common.footer.policies}
                </a>
                <a
                  href={
                    linkToInternalSite(
                      footerType === FooterType.LOGGED_IN
                        ? '/vilkar/med-virksomhetskonto'
                        : '/vilkar/uten-virksomhetskonto'
                    ).href
                  }
                  className="hw-link hw-text-small footer-link"
                >
                  {translation.common.frontpage.private.footer.terms}
                </a>
              </div>
            </nav>
          </>
        )}

        <ChangeLanguage />
      </div>
    </footer>
  );
};

export enum FooterType {
  LOGGED_IN = 'LOGGED_IN',
  NOT_LOGGED_IN = 'NOT_LOGGED_IN',
  ADMIN = 'ADMIN',
}

interface FooterProps {
  footerType: FooterType;
  inSigningProcess?: boolean;
}

export default Footer;
