import { FunctionComponent, useEffect, RefObject, useRef } from 'react';
import { useTracking } from '@common/analytics/Analytics';
import { ChevronDownIcon, ChevronUpIcon, IconSize } from '@hedwig/icons';
import { FRONTPAGE_CLICK } from '../organization/constants';

export function useOutsideClick(
  ref: RefObject<HTMLDivElement>,
  setExpanded: (value: boolean) => void
) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setExpanded(false);
      }
    }

    document.addEventListener('click', handleClickOutside, {
      capture: true,
    });
    return () => {
      document.removeEventListener('click', handleClickOutside, {
        capture: true,
      });
    };
  }, [ref, setExpanded]);
}

const HeaderDropDown: FunctionComponent<HeaderDropDownProps> = ({
  buttonText,
  listItems,
  name,
  active,
  expanded,
  setExpanded,
  dataTestid,
}) => {
  const { registerAnalyticsEvent } = useTracking();
  const node = useRef<HTMLDivElement>(null);
  useOutsideClick(node, setExpanded);

  return (
    <div ref={node}>
      <button
        className={`navbar__menu-item navbar__menu_item-button ${
          active ? 'navbar__menu-item--active' : ''
        }`}
        onClick={() => setExpanded(!expanded)}
        aria-expanded={expanded}
        aria-controls={`change-${name}-dropdown`}
        data-testid={dataTestid}
        type="button"
      >
        {buttonText}
        {expanded ? (
          <ChevronUpIcon
            size={IconSize.SMALL}
            extraClassName="language-icon icon-with-text--left icon-header"
          />
        ) : (
          <ChevronDownIcon
            size={IconSize.SMALL}
            extraClassName="language-icon icon-with-text--left icon-header"
          />
        )}
      </button>
      <div
        id={`change-${name}-dropdown`}
        className="navbar__dropdown"
        aria-live="polite"
      >
        {expanded && (
          <div>
            <ul className="hw-list hw-list--no-bullets navbar__dropdown__list">
              {listItems.map((item, index) => (
                <li key={item.text}>
                  {item.openInNewTab ? (
                    <a
                      className="navbar__dropdown__list-item"
                      data-testid={`${dataTestid}-${index}`}
                      href={item.href}
                      onClick={() => {
                        setExpanded(false);
                        registerAnalyticsEvent({
                          category: 'Landingsside',
                          action: FRONTPAGE_CLICK,
                          label: 'Trykket på ' + buttonText + ': ' + item.text,
                        });
                      }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.text}
                    </a>
                  ) : (
                    <a
                      className="navbar__dropdown__list-item"
                      data-testid={`${dataTestid}-${index}`}
                      href={item.href}
                      onClick={() => {
                        setExpanded(false);
                        registerAnalyticsEvent({
                          category: 'Landingsside',
                          action: FRONTPAGE_CLICK,
                          label: 'Trykket på ' + buttonText + ': ' + item.text,
                        });
                      }}
                    >
                      {item.text}
                    </a>
                  )}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

interface HeaderDropDownProps {
  buttonText: string;
  listItems: Array<{ text: string; href: string; openInNewTab?: boolean }>;
  name: string;
  active: boolean;
  expanded: boolean;
  setExpanded: (value: boolean) => void;
  dataTestid: string;
}

export default HeaderDropDown;
