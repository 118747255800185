import { FunctionComponent, PropsWithChildren } from 'react';

import { addClassesFromProps } from '../basicFunctions';
import MainErrorBoundary from '../errorHandling/MainErrorBoundary';

const Main: FunctionComponent<MainProps & PropsWithChildren> = ({
  extraClassName,
  children,
}) => {
  return (
    <main className={`content${addClassesFromProps(extraClassName)}`} id="main">
      <MainErrorBoundary>{children}</MainErrorBoundary>
    </main>
  );
};

interface MainProps {
  extraClassName?: string;
}

export default Main;
