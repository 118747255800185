import { FunctionComponent } from 'react';
import { Button, buttonStyles } from '.';
import { addClassesFromProps } from '@common/basicFunctions';
import { CloseIcon, IconColor, IconSet, IconSize } from '../icons';

interface Props {
  onClick: () => void;
  ariaLabel?: string;
  iconColor?: IconColor;
  iconSet?: IconSet;
  iconSize?: IconSize;
  extraClassName?: string;
}

const CloseButton: FunctionComponent<Props> = ({
  onClick,
  ariaLabel,
  iconColor,
  iconSet,
  iconSize,
  extraClassName = 'confirm-cancel-image-wrapper',
}) => (
  <Button
    onClick={(e) => {
      e.preventDefault();
      onClick();
    }}
    buttonStyle={buttonStyles.transparent}
    extraClassName={`button-default-disable${addClassesFromProps(
      extraClassName
    )}`}
    ariaLabel={ariaLabel}
  >
    <CloseIcon
      extraClassName="icon-cancel-confirm"
      color={iconColor}
      set={iconSet}
      size={iconSize}
    />
  </Button>
);

export default CloseButton;
