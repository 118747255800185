import config from '../config';

export const goTo = (path: string) => {
  if (config.enableExternalNavigation) {
    window.location.replace(path);
  } else {
    window.mocklocation = path;
  }
};

export const linkToInternalSite = (path: string): URL => {
  return new URL(window.location.origin + path);
};

export const linkToLandingPage = (): URL => {
  return linkToInternalSite('');
};
