let csrfToken: string;

export function saveCsrfToken(headers: Headers): void {
  const token = headers.get('X-CSRF-Token');
  if (token) {
    setCsrfToken(token);
  }
}

export function setCsrfToken(token: string): void {
  csrfToken = token;
}

export function addCsrfHeader(headers?: HeadersInit): HeadersInit {
  return {
    ...(headers || {}),
    'X-CSRF-Token': csrfToken,
  } as HeadersInit;
}
