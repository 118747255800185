import { FunctionComponent } from 'react';
import { useI18n } from './i18n';

interface OnClickLanguageButtonProps {
  callback?: () => void;
}

const OnClickLanguageButton: FunctionComponent<OnClickLanguageButtonProps> = ({
  callback = () => ({}),
}) => {
  const [, , , setLanguage] = useI18n();
  const listItems = [
    {
      text: 'Bokmål',
      callback: () => {
        setLanguage('nb');
        callback();
      },
    },
    {
      text: 'English',
      callback: () => {
        setLanguage('en');
        callback();
      },
    },
    {
      text: 'Nynorsk',
      callback: () => {
        setLanguage('nn');
        callback();
      },
    },
  ];

  return (
    <ul className="hw-list hw-list--no-bullets">
      {listItems.map((item) => (
        <li key={item.text}>
          <button
            className="button-header-dropdown-item"
            onClick={item.callback}
            type="button"
          >
            {item.text}
          </button>
        </li>
      ))}
    </ul>
  );
};

export default OnClickLanguageButton;
