import { FunctionComponent, PropsWithChildren } from 'react';

interface LinkToNewTabProps {
  href: string;
  size?: string;
  onClickFunc?: () => void;
}

const LinkToNewTab: FunctionComponent<
  LinkToNewTabProps & PropsWithChildren
> = ({ href, children, size, onClickFunc }) => {
  return (
    <a
      href={href}
      className={`hw-link ${size === 'small' ? 'hw-link--small' : ''}`}
      target="_blank"
      rel="noopener noreferrer"
      onClick={onClickFunc}
    >
      {children}
    </a>
  );
};

export default LinkToNewTab;
