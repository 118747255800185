import { ErrorBoundary } from '@sentry/react';
import { FunctionComponent, PropsWithChildren } from 'react';
import UnhandledError from './UnhandledError';

const MainErrorBoundary: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  return (
    <ErrorBoundary fallback={() => <UnhandledError />}>
      {children}
    </ErrorBoundary>
  );
};

export default MainErrorBoundary;
