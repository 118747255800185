import { FunctionComponent } from 'react';
import { addClassesFromProps } from '../basicFunctions';
import { useI18n } from '../language/i18n';
import ContactSupport from './ContactSupport';

const ContactSupportOptions: FunctionComponent<{ className?: string }> = ({
  className,
}) => {
  const [translation] = useI18n();

  return (
    <div className={`hw-flex${addClassesFromProps(className)}`}>
      <ContactSupport
        heading={translation.help.contact_info.difi_sub_heading}
        description={translation.org.help.contact_info.difi_sub_text}
        email="servicedesk@digdir.no"
        phoneNumber={translation.help.contact_info.difi_telephone}
        openingHours={translation.help.contact_info.difi_opening_hours}
      />
      <ContactSupport
        heading={translation.org.help.contact_info.posten_sub_heading}
        description={translation.org.help.contact_info.posten_sub_text}
        email="kundeservice@digipost.no"
        phoneNumber={translation.org.help.contact_info.telephone}
        internationalPhoneNumber={translation.org.help.contact_info.from_abroad}
        openingHours={translation.help.contact_info.monday_to_friday}
      />
      <ContactSupport
        heading={translation.help.contact_info.posten_sub_heading}
        description={translation.help.contact_info.posten_sub_text}
        email="kundeservice@digipost.no"
        phoneNumber={translation.help.contact_info.telephone}
        openingHours={translation.help.contact_info.monday_to_friday}
      />
    </div>
  );
};

export default ContactSupportOptions;
