const idUrlSubstitution = {
  regExp: new RegExp('\\d+', 'g'),
  replacement: '_id_',
};

const tokenUrlSubstitution = {
  regExp: new RegExp('[A-Za-z0-9_-]{43}', 'g'),
  replacement: '_token_',
};

const uuidUrlSubstitution = {
  regExp: new RegExp(
    '[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}',
    'g'
  ),
  replacement: '_uuid_',
};

/**
 * Important that tokenUrlSubstitiuon before id UrlSubstition,
 * because there are possible id's inside the tokens.
 */
const substitutions = [
  tokenUrlSubstitution,
  uuidUrlSubstitution,
  idUrlSubstitution,
];

export const subsituteIdsWithPlacedholders = (path: string) => {
  return substitutions.reduce(
    (p, substitution) =>
      substitution.regExp[Symbol.replace](p, substitution.replacement),
    path
  );
};
