import download from 'downloadjs';
import { DecoderFunction, Pojo } from 'typescript-json-decoder';

import { addCsrfHeader, saveCsrfToken } from './csrf';

export interface ApiError<ApiErrorResponse> extends Error {
  name: 'ApiError';
  statusCode?: number;
  errorResponse?: ApiErrorResponse;
}

async function tryParseBody(response: Response) {
  try {
    // eslint-disable-next-line
    return await response.json();
  } catch (error) {
    // We do not always get an error response, so
    // we can ignore parsing errorResponse if not present.
    return undefined;
  }
}

async function apiError<ApiErrorResponse>(
  apiErrorResponseDecoder: DecoderFunction<ApiErrorResponse>,
  url: string,
  response: Response
): Promise<ApiError<ApiErrorResponse>> {
  return {
    name: 'ApiError',
    message: `Failed to fetch from ${url} with statusCode ${response.status}`,
    statusCode: response.status,
    errorResponse: await tryParseBody(response).then((maybeParseBody: Pojo) =>
      maybeParseBody === undefined
        ? undefined
        : apiErrorResponseDecoder(maybeParseBody)
    ),
  };
}

function decodeError(e: unknown): ApiError<unknown> {
  return {
    name: 'ApiError',
    message:
      typeof e === 'string'
        ? `Could not parse api response. Reason: ${e}`
        : e && (e as Error).message
        ? `Could not parse api response. Reason: ${(e as Error).message}`
        : `Could not parse api response.`,
  };
}

export async function get<T, ApiErrorResponse>(
  errorDecoder: DecoderFunction<ApiErrorResponse>,
  url: string,
  decoder: DecoderFunction<T>,
  timeout = 15000
): Promise<T> {
  try {
    const controller = new AbortController();
    const signal = controller.signal;
    const id = setTimeout(() => controller.abort(), timeout);

    const res = await fetch(url, {
      headers: {
        accept: 'application/json',
        ...addCsrfHeader(),
      },
      signal,
    });

    clearTimeout(id);

    saveCsrfToken(res.headers);

    if (!res.ok) {
      await apiError(errorDecoder, url, res).then((err) => {
        throw err;
      });
    }
    return await res?.json().then(decoder);
  } catch (e) {
    if (e && (e as ApiError<ApiErrorResponse>).name === 'ApiError') {
      throw e as ApiError<ApiErrorResponse>;
    }
    throw decodeError(e);
  }
}

export async function getLocation<ApiErrorResponse>(
  errorDecoder: DecoderFunction<ApiErrorResponse>,
  url: string,
  headers: HeadersInit = {},
  timeout = 15000
): Promise<string> {
  try {
    const controller = new AbortController();
    const signal = controller.signal;
    const id = setTimeout(() => controller.abort(), timeout);

    const res = await fetch(url, {
      headers: {
        accept: 'application/json',
        ...addCsrfHeader(),
        ...headers,
      },
      signal,
    });

    clearTimeout(id);

    saveCsrfToken(res.headers);

    if (!res.ok) {
      await apiError(errorDecoder, url, res).then((err) => {
        throw err;
      });
    }

    const location = res?.headers.get('location');
    if (location === null) {
      throw new Error('Expected response to include "location" header.');
    }
    return location;
  } catch (e) {
    if (e && (e as ApiError<ApiErrorResponse>).name === 'ApiError') {
      throw e as ApiError<ApiErrorResponse>;
    }
    throw decodeError(e);
  }
}

export async function putWithResponse<T, ApiErrorResponse>(
  errorDecoder: DecoderFunction<ApiErrorResponse>,
  url: string,
  decoder: DecoderFunction<T>
): Promise<T> {
  try {
    const res = await fetch(url, {
      method: 'PUT',
      headers: addCsrfHeader(),
    });

    saveCsrfToken(res.headers);

    if (!res.ok) {
      await apiError(errorDecoder, url, res).then((err) => {
        throw err;
      });
    }
    return await res.json().then(decoder);
  } catch (e) {
    if (e && (e as ApiError<ApiErrorResponse>).name === 'ApiError') {
      throw e as ApiError<ApiErrorResponse>;
    }
    throw decodeError(e);
  }
}

export async function put<ApiErrorResponse>(
  errorDecoder: DecoderFunction<ApiErrorResponse>,
  url: string,
  body?: BodyInit,
  headers?: HeadersInit
): Promise<void> {
  try {
    const res = await fetch(url, {
      method: 'PUT',
      headers: {
        ...addCsrfHeader(),
        Accept: 'application/json',
        ...headers,
      },
      body,
    });

    saveCsrfToken(res.headers);

    if (!res.ok) {
      await apiError(errorDecoder, url, res).then((err) => {
        throw err;
      });
    }
    return;
  } catch (e) {
    if (e && (e as ApiError<ApiErrorResponse>).name === 'ApiError') {
      throw e as ApiError<ApiErrorResponse>;
    }
    throw decodeError(e);
  }
}

export async function postForm<T, ApiErrorResponse>(
  errorDecoder: DecoderFunction<ApiErrorResponse>,
  url: string,
  decoder: DecoderFunction<T>,
  form: FormData
): Promise<T> {
  const options = {
    method: 'POST',
    headers: addCsrfHeader({
      Accept: 'application/json',
    }),
    body: form,
  };
  try {
    const res = await fetch(url, options);

    saveCsrfToken(res.headers);

    if (!res.ok) {
      await apiError(errorDecoder, url, res).then((err) => {
        throw err;
      });
    }
    return await res.json().then(decoder);
  } catch (e) {
    if (e && (e as ApiError<ApiErrorResponse>).name === 'ApiError') {
      throw e as ApiError<ApiErrorResponse>;
    }
    throw decodeError(e);
  }
}

export async function postFormWithoutResponse<ApiErrorResponse>(
  errorDecoder: DecoderFunction<ApiErrorResponse>,
  url: string,
  form: FormData
): Promise<void> {
  const options = {
    method: 'POST',
    headers: addCsrfHeader({
      Accept: 'application/json',
    }),
    body: form,
  };
  try {
    const res = await fetch(url, options);

    saveCsrfToken(res.headers);

    if (!res.ok) {
      await apiError(errorDecoder, url, res).then((err) => {
        throw err;
      });
    }
    return;
  } catch (e) {
    if (e && (e as ApiError<ApiErrorResponse>).name === 'ApiError') {
      throw e as ApiError<ApiErrorResponse>;
    }
    throw decodeError(e);
  }
}

export async function post<T, ApiErrorResponse>(
  errorDecoder: DecoderFunction<ApiErrorResponse>,
  url: string,
  decoder: DecoderFunction<T>,
  body?: BodyInit,
  headers: HeadersInit = {}
): Promise<T> {
  const options = {
    method: 'POST',
    headers: addCsrfHeader({
      Accept: 'application/json',
      ...headers,
    }),
    body,
  };
  try {
    const res = await fetch(url, options);

    saveCsrfToken(res.headers);

    if (!res.ok) {
      await apiError(errorDecoder, url, res).then((err) => {
        throw err;
      });
    }
    return await res.json().then(decoder);
  } catch (e) {
    if (e && (e as ApiError<ApiErrorResponse>).name === 'ApiError') {
      throw e as ApiError<ApiErrorResponse>;
    }
    throw decodeError(e);
  }
}

export async function postWithoutResponse<ApiErrorResponse>(
  errorDecoder: DecoderFunction<ApiErrorResponse>,
  url: string,
  body?: BodyInit
): Promise<void> {
  const options = {
    method: 'POST',
    headers: addCsrfHeader({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }),
    body,
  };
  try {
    const res = await fetch(url, options);

    saveCsrfToken(res.headers);

    if (!res.ok) {
      await apiError(errorDecoder, url, res).then((err) => {
        throw err;
      });
    }
    return;
  } catch (e) {
    if (e && (e as ApiError<ApiErrorResponse>).name === 'ApiError') {
      throw e as ApiError<ApiErrorResponse>;
    }
    throw decodeError(e);
  }
}

export async function getFile<ApiErrorResponse>(
  errorDecoder: DecoderFunction<ApiErrorResponse>,
  url: string,
  mimeType = 'application/pdf'
): Promise<Blob> {
  try {
    const res = await fetch(url, {
      headers: addCsrfHeader({
        Accept: mimeType,
      }),
    });

    saveCsrfToken(res.headers);

    if (!res.ok) {
      await apiError(errorDecoder, url, res).then((err) => {
        throw err;
      });
    }
    return await res.blob();
  } catch (e) {
    if (e && (e as ApiError<ApiErrorResponse>).name === 'ApiError') {
      throw e as ApiError<ApiErrorResponse>;
    }
    throw decodeError(e);
  }
}

export async function del<ApiErrorResponse>(
  errorDecoder: DecoderFunction<ApiErrorResponse>,
  url: string,
  headers: HeadersInit = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
): Promise<void> {
  const options = {
    method: 'DELETE',
    headers: addCsrfHeader(headers),
  };

  try {
    const res = await fetch(url, options);

    saveCsrfToken(res.headers);

    if (!res.ok) {
      await apiError(errorDecoder, url, res).then((err) => {
        throw err;
      });
    }
    return;
  } catch (e) {
    if (e && (e as ApiError<ApiErrorResponse>).name === 'ApiError') {
      throw e as ApiError<ApiErrorResponse>;
    }
    throw decodeError(e);
  }
}

export async function delWithResponse<T, ApiErrorResponse>(
  errorDecoder: DecoderFunction<ApiErrorResponse>,
  url: string,
  decoder: DecoderFunction<T>,
  headers: HeadersInit = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
): Promise<T> {
  const options = {
    method: 'DELETE',
    headers: addCsrfHeader(headers),
  };

  try {
    const res = await fetch(url, options);

    saveCsrfToken(res.headers);

    if (!res.ok) {
      await apiError(errorDecoder, url, res).then((err) => {
        throw err;
      });
    }
    return await res.json().then(decoder);
  } catch (e) {
    if (e && (e as ApiError<ApiErrorResponse>).name === 'ApiError') {
      throw e as ApiError<ApiErrorResponse>;
    }
    throw decodeError(e);
  }
}

export async function patch<ApiErrorResponse>(
  errorDecoder: DecoderFunction<ApiErrorResponse>,
  url: string,
  body?: BodyInit,
  headers: HeadersInit = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
): Promise<void> {
  const options = {
    method: 'PATCH',
    headers: addCsrfHeader(headers),
    body,
  };

  try {
    const res = await fetch(url, options);

    saveCsrfToken(res.headers);

    if (!res.ok) {
      await apiError(errorDecoder, url, res).then((err) => {
        throw err;
      });
    }
    return;
  } catch (e) {
    if (e && (e as ApiError<ApiErrorResponse>).name === 'ApiError') {
      throw e as ApiError<ApiErrorResponse>;
    }
    throw decodeError(e);
  }
}

const getFileName = (response: Response): string | undefined => {
  const contentDisposition = response?.headers
    ?.get('content-disposition')
    ?.match(/"(?:\\.|[^"\\]*)"/);
  if (contentDisposition && contentDisposition.length > 0) {
    return contentDisposition[0].replace(/['"]+/g, '');
  }
};

export async function downloadDocument<ApiErrorResponse>(
  errorDecoder: DecoderFunction<ApiErrorResponse>,
  url: string
) {
  try {
    const controller = new AbortController();
    const signal = controller.signal;
    const id = setTimeout(() => controller.abort(), 30000);
    const res = await fetch(url, {
      headers: { 'Content-Type': 'application/pdf' },
      credentials: 'same-origin',
      signal,
    });
    clearTimeout(id);
    if (!res.ok) {
      await apiError(errorDecoder, url, res).then((err) => {
        throw err;
      });
    } else {
      download(await res.blob(), getFileName(res));
    }
  } catch (e: unknown) {
    const error =
      ((e || {}) as Error).name === 'AbortError'
        ? new Error('Download aborted because of timeout after 30 sec.')
        : e;

    if ((error as ApiError<ApiErrorResponse>).name === 'ApiError') {
      throw error as ApiError<ApiErrorResponse>;
    }
    throw decodeError(error);
  }
}
