import {
  add,
  endOfDay,
  endOfHour,
  endOfMonth,
  endOfWeek,
  endOfYear,
  startOfDay,
  startOfHour,
  startOfMonth,
  startOfWeek,
  startOfYear,
  differenceInDays,
} from 'date-fns';

export const DAYS_IN_YEAR = 365;

export enum TimeUnit {
  YEAR = 'year',
  MONTH = 'month',
  WEEK = 'week',
  DAY = 'day',
  HOUR = 'hour',
}

export const startOf = (timeUnit: TimeUnit, date: Date): Date => {
  switch (timeUnit) {
    case TimeUnit.DAY:
      return startOfDay(date);
    case TimeUnit.WEEK:
      return startOfWeek(date);
    case TimeUnit.MONTH:
      return startOfMonth(date);
    case TimeUnit.YEAR:
      return startOfYear(date);
    case TimeUnit.HOUR:
      return startOfHour(date);
  }
};

export const endOf = (timeUnit: TimeUnit, date: Date): Date => {
  switch (timeUnit) {
    case TimeUnit.DAY:
      return endOfDay(date);
    case TimeUnit.WEEK:
      return endOfWeek(date);
    case TimeUnit.MONTH:
      return endOfMonth(date);
    case TimeUnit.YEAR:
      return endOfYear(date);
    case TimeUnit.HOUR:
      return endOfHour(date);
  }
};

export const addDuration = (
  timeUnit: TimeUnit,
  amount: number,
  date: Date
): Date => {
  switch (timeUnit) {
    case TimeUnit.DAY:
      return add(date, { days: amount });
    case TimeUnit.WEEK:
      return add(date, { weeks: amount });
    case TimeUnit.MONTH:
      return add(date, { months: amount });
    case TimeUnit.YEAR:
      return add(date, { years: amount });
    case TimeUnit.HOUR:
      return add(date, { hours: amount });
  }
};

export const getDifferenceInDays = (
  laterDate: Date | number,
  earlierDate: Date | number
): number => {
  return differenceInDays(laterDate, earlierDate);
};
