import { FunctionComponent, PropsWithChildren, useState } from 'react';

import { Button, buttonStyles } from '@hedwig/buttons';
import { ChevronDownIcon, ChevronUpIcon, IconSize } from '@hedwig/icons';
import { useI18n } from '../../language/i18n';

const ReadMore: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [readMore, setReadMore] = useState(false);
  const [translation] = useI18n();

  return (
    <>
      <div className="hw-grid__item hw-full hw-block--mt-small">
        {readMore ? (
          <Button
            extraClassName="text-button__show-more hw-text-small"
            onClick={() => setReadMore(!readMore)}
            buttonStyle={buttonStyles.transparent}
          >
            <ChevronUpIcon
              size={IconSize.SMALL}
              extraClassName="text-button__icon icon-with-text"
            />
            {translation.portal.consent.show_less}
          </Button>
        ) : (
          <Button
            extraClassName="text-button__show-more hw-text-small"
            onClick={() => setReadMore(!readMore)}
          >
            <ChevronDownIcon
              size={IconSize.SMALL}
              extraClassName="text-button__icon icon-with-text"
            />
            {translation.portal.consent.show_more}
          </Button>
        )}
      </div>
      {readMore && children}
    </>
  );
};

export default ReadMore;
