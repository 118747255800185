import { FunctionComponent } from 'react';
import FaIcon, { FaIconProps } from './FaIcon';

export enum IconSize {
  DEFAULT = 'default',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  EXTRA_LARGE = 'extra-large',
}

export enum IconColor {
  BLACK = 'black',
  GREEN = 'green',
  YELLOW = 'yellow',
  PRIMARY = 'primary',
  PRIMARY_DARK = 'primary-dark',
  PRIMARY_DARKER = 'primary-darker',
  GRAY_DARKER = 'gray-darker',
  GRAY_LIGHT = 'gray-light',
  WHITE = 'white',
}

export enum IconSet {
  REGULAR = 'regular',
  LIGHT = 'light',
  SOLID = 'solid',
}

type IconProps = Omit<FaIconProps, 'icon'>;

export const ChevronLeftIcon: FunctionComponent<IconProps> = (props) => (
  <FaIcon icon="chevron-left" {...props} />
);

export const ChevronDownIcon: FunctionComponent<IconProps> = (props) => {
  return <FaIcon icon="chevron-down" {...props} />;
};

export const ChevronUpIcon: FunctionComponent<IconProps> = (props) => (
  <FaIcon icon="chevron-up" {...props} />
);

export const ChevronRightIcon: FunctionComponent<IconProps> = (props) => {
  return <FaIcon icon="chevron-right" {...props} />;
};

export const ArrowUpIcon: FunctionComponent<IconProps> = (props) => {
  return <FaIcon icon="arrow-up" {...props} />;
};

export const ArrowDownIcon: FunctionComponent<IconProps> = (props) => {
  return <FaIcon icon="arrow-down" {...props} />;
};

export const ClockIcon: FunctionComponent<IconProps> = (props) => (
  <FaIcon icon="clock" {...props} />
);

export const BellIcon: FunctionComponent<IconProps> = (props) => (
  <FaIcon icon="bell" {...props} />
);

export const DownloadIcon: FunctionComponent<IconProps> = (props) => (
  <FaIcon icon="arrow-alt-to-bottom" {...props} />
);

export const EmailIcon: FunctionComponent<IconProps> = (props) => (
  <FaIcon icon="envelope" {...props} />
);

export const CalenderAltIcon: FunctionComponent<IconProps> = (props) => (
  <FaIcon icon="calendar-alt" {...props} />
);

export const ErrorIcon: FunctionComponent<IconProps> = ({
  color = IconColor.YELLOW,
  ...props
}) => <FaIcon icon="exclamation-circle" color={color} {...props} />;

export const FailedIcon: FunctionComponent<Omit<IconProps, 'color'>> = (
  props
) => <FaIcon icon="times-circle" color={IconColor.YELLOW} {...props} />;

export const MobileIcon: FunctionComponent<IconProps> = (props) => (
  <FaIcon icon="mobile" {...props} />
);

export const PaperPlaneIcon: FunctionComponent<IconProps> = (props) => (
  <FaIcon icon="paper-plane" {...props} />
);

export const PartialSuccessIcon: FunctionComponent<Omit<IconProps, 'color'>> = (
  props
) => <FaIcon icon="check-circle" color={IconColor.GRAY_DARKER} {...props} />;

export const SuccessIcon: FunctionComponent<IconProps> = (props) => (
  <FaIcon icon="check-circle" color={IconColor.GREEN} {...props} />
);

export const ExclamationTriangleIcon: FunctionComponent<
  Omit<IconProps, 'color'>
> = (props) => (
  <FaIcon icon="exclamation-triangle" color={IconColor.YELLOW} {...props} />
);

export const TimesCircleIcon: FunctionComponent<IconProps> = ({
  color = IconColor.PRIMARY_DARKER,
  ...props
}) => <FaIcon icon="times-circle" color={color} {...props} />;

export const UploadFileIcon: FunctionComponent<IconProps> = (props) => (
  <FaIcon icon="file-upload" {...props} />
);

export const LinkIcon: FunctionComponent<IconProps> = (props) => (
  <FaIcon icon="link" {...props} />
);

export const StarIcon: FunctionComponent<IconProps> = (props) => (
  <FaIcon icon="star" {...props} />
);

export const SearchIcon: FunctionComponent<IconProps> = (props) => (
  <FaIcon icon="search" {...props} />
);

export const PlusIcon: FunctionComponent<IconProps> = (props) => (
  <FaIcon icon="plus" {...props} />
);

export const MinusIcon: FunctionComponent<IconProps> = (props) => (
  <FaIcon icon="minus" {...props} />
);

export const InfoIcon: FunctionComponent<IconProps> = (props) => (
  <FaIcon icon="info-circle" {...props} />
);

export const ExclamationIcon: FunctionComponent<IconProps> = (props) => (
  <FaIcon icon="exclamation-circle" {...props} />
);

export const HelpIcon: FunctionComponent<IconProps> = (props) => (
  <FaIcon icon="question-circle" {...props} />
);

export const CogsIcon: FunctionComponent<IconProps> = (props) => (
  <FaIcon icon="cogs" {...props} />
);

export const DesktopIcon: FunctionComponent<IconProps> = (props) => (
  <FaIcon icon="desktop" {...props} />
);

export const CommentsAltIcon: FunctionComponent<IconProps> = (props) => (
  <FaIcon icon="comments-alt" {...props} />
);

export const CloseIcon: FunctionComponent<IconProps> = (props) => (
  <FaIcon icon="times" {...props} />
);

export const UserIcon: FunctionComponent<IconProps> = (props) => (
  <FaIcon icon="user" {...props} />
);

export const ArrowUprightFromSquareIcon: FunctionComponent<IconProps> = (
  props
) => <FaIcon icon="arrow-up-right-from-square" {...props} />;

export const PenToSquareIcon: FunctionComponent<IconProps> = (props) => (
  <FaIcon icon="pen-to-square" {...props} />
);

export const CircleOneIcon: FunctionComponent<IconProps> = (props) => (
  <FaIcon icon="circle-1" {...props} />
);

export const CircleTwoIcon: FunctionComponent<IconProps> = (props) => (
  <FaIcon icon="circle-2" {...props} />
);

export const CircleThreeIcon: FunctionComponent<IconProps> = (props) => (
  <FaIcon icon="circle-3" {...props} />
);

export const BriefCaseIcon: FunctionComponent<IconProps> = (props) => (
  <FaIcon icon="briefcase-blank" {...props} />
);

export const GearIcon: FunctionComponent<IconProps> = (props) => (
  <FaIcon icon="gear" {...props} />
);

export const CopyrightIcon: FunctionComponent<IconProps> = (props) => (
  <FaIcon icon="copyright" {...props} />
);

export const GearsIcon: FunctionComponent<IconProps> = (props) => (
  <FaIcon icon="gears" {...props} />
);

export const BuildingIcon: FunctionComponent<IconProps> = (props) => (
  <FaIcon icon="building" {...props} />
);

export const SlidersIcon: FunctionComponent<IconProps> = (props) => (
  <FaIcon icon="sliders" {...props} />
);

export const GlobeIcon: FunctionComponent<IconProps> = (props) => (
  <FaIcon icon="globe" {...props} />
);

export { default as HourGlassIcon } from './HourGlassIcon';
