import { DecoderFunction, union } from 'typescript-json-decoder';

export enum OrganizationSector {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
}
export const organizationSectorDecoder: DecoderFunction<OrganizationSector> = (
  value
) => {
  return union(...Object.values(OrganizationSector))(value);
};
