import { FunctionComponent, PropsWithChildren, useState } from 'react';
import { Collapse } from 'react-collapse';
import { v4 as uuidv4 } from 'uuid';

import { addClassesFromProps } from '@common/basicFunctions';
import { ChevronDownIcon, ChevronUpIcon, IconColor, IconSize } from '../icons';

import './curtain.less';

interface CurtainProps {
  text: string;
  wrapperClassName?: string;
  triggerClassName?: string;
  textClassName?: string;
  contentClassName?: string;
  buttonId?: string;
  curtainClass?: string;
  logToAnalyticsOnClick?: () => void;
  id?: string;
  expandCurtain?: boolean;
  dataTestid?: string;
  iconSize?: IconSize;
}

const Curtain: FunctionComponent<CurtainProps & PropsWithChildren> = ({
  text,
  children,
  wrapperClassName,
  triggerClassName,
  textClassName,
  contentClassName,
  buttonId,
  curtainClass,
  logToAnalyticsOnClick,
  id,
  expandCurtain,
  dataTestid,
  iconSize = IconSize.MEDIUM,
}) => {
  const [expanded, setExpanded] = useState(expandCurtain || false);
  const [uniqueId] = useState(uuidv4());

  return (
    <div
      id={id}
      className={`curtain${addClassesFromProps(curtainClass)}`}
      data-testid={dataTestid}
    >
      <div
        className={`curtain__item${addClassesFromProps(wrapperClassName)} ${
          expanded ? 'curtain__item--expanded' : ''
        }`}
      >
        <>
          <button
            type="button"
            id={buttonId}
            className={`curtain__trigger${addClassesFromProps(
              triggerClassName
            )}`}
            aria-expanded={expanded}
            aria-controls={uniqueId}
            onClick={(e) => {
              e.preventDefault();
              if (!expanded && logToAnalyticsOnClick) {
                logToAnalyticsOnClick();
              }
              setExpanded(!expanded);
            }}
            data-testid={`${dataTestid || 'unkown'}-toggle-button`}
          >
            {expanded ? (
              <ChevronUpIcon
                size={iconSize}
                color={IconColor.PRIMARY_DARK}
                extraClassName="curtain_icon"
              />
            ) : (
              <ChevronDownIcon
                size={iconSize}
                color={IconColor.PRIMARY_DARK}
                extraClassName="curtain_icon"
              />
            )}
            <span className={textClassName}>{text}</span>
          </button>
          <div
            id={uniqueId}
            className={`curtain__contents${addClassesFromProps(
              contentClassName
            )} ${
              expanded
                ? 'curtain__contents-expanded'
                : 'curtain__contents-collapsed'
            }`}
          >
            <Collapse isOpened={expanded}>{children}</Collapse>
          </div>
        </>
      </div>
    </div>
  );
};

export default Curtain;
