import {
  useEffect,
  useState,
  createContext,
  FunctionComponent,
  useContext,
  useCallback,
  PropsWithChildren,
} from 'react';
import { addDuration, TimeUnit } from '../time/time';
import {
  disableSiteImproveTracking,
  enableSiteImproveTracking,
  trackSiteImproveEvent,
  trackSiteImprovePageView,
} from './siteImprove';
import { IS_NOT_LOCALHOST } from '../environment';

export interface AnalyticsEvent {
  category: string;
  action: string;
  label?: string;
}

export interface ConsentType {
  analytics: boolean;
  marketing: boolean;
  version?: number;
}

const CONSENT_COOKIE_NAME = 'posten_signering_consent';

const findCookie = (name: string) =>
  document.cookie.split('; ').find((row) => row.startsWith(name));

function getValueFromCookie<CookieType>(cookie: string) {
  return JSON.parse(cookie.split('=')[1]) as CookieType;
}

const getConsent = (): { isConsentSaved: boolean; consent: ConsentType } => {
  const consentCookie = findCookie(CONSENT_COOKIE_NAME);
  if (!consentCookie) {
    return {
      isConsentSaved: false,
      consent: {
        analytics: true,
        marketing: false,
      },
    };
  } else {
    const consent = getValueFromCookie<ConsentType>(consentCookie);
    return {
      isConsentSaved: consent.version === 2,
      consent,
    };
  }
};

const setupTracking = (consent: ConsentType) => {
  if (consent.version === 2 && consent.analytics) {
    enableSiteImproveTracking();
  } else {
    disableSiteImproveTracking();
  }
};

const updateConsentCookie = (consent: ConsentType) => {
  document.cookie = `posten_signering_consent=${JSON.stringify(
    consent
  )}; Domain=${
    IS_NOT_LOCALHOST ? '.signering.posten.no' : 'localhost'
  }; Path=/; expires=${addDuration(TimeUnit.DAY, 90, new Date()).toString()};`;
};

const initialConsent = getConsent();
const ConsentContext = createContext({
  ...initialConsent,
  showConsentDialog: false,
  canShowCookieBanner: false,
  setCanShowCookieBanner: (_: boolean) => {
    return;
  },
  saveConsent: (_: ConsentType) => {
    return;
  },
  setShowConsentDialog: (_: boolean) => {
    return;
  },
});

export const AppWithTracking: FunctionComponent<
  {
    options?: {
      enableMarketingTracking?: boolean;
      canShowCookieBanner?: boolean;
    };
  } & PropsWithChildren
> = ({
  children,
  options = { enableMarketingTracking: false, canShowCookieBanner: false },
}) => {
  const [consent, setConsent] = useState(initialConsent.consent);
  const [isConsentSaved, setIsConsentSaved] = useState(
    initialConsent.isConsentSaved
  );
  const [hasUserSavedNow, setHasUserSavedNow] = useState(false);
  const [showConsentDialog, setShowConsentDialog] = useState(false);
  const [cookieBannerEnabled, setCookieBannerEnabled] = useState(
    !!options.canShowCookieBanner
  );

  useEffect(() => {
    if (hasUserSavedNow) {
      updateConsentCookie(consent);
      setHasUserSavedNow(false);
    }
  }, [consent, hasUserSavedNow]);

  useEffect(() => {
    if (isConsentSaved) {
      setupTracking(consent);
    }
  }, [consent, isConsentSaved]);

  const saveConsent = (consentToSave: ConsentType) => {
    setConsent({ ...consentToSave, version: 2 });
    setIsConsentSaved(true);
    setHasUserSavedNow(true);
  };

  return (
    <ConsentContext.Provider
      value={{
        consent,
        isConsentSaved,
        saveConsent,
        canShowCookieBanner: cookieBannerEnabled,
        setCanShowCookieBanner: setCookieBannerEnabled,
        setShowConsentDialog,
        showConsentDialog,
      }}
    >
      {children}
    </ConsentContext.Provider>
  );
};

export const useTracking = () => {
  const {
    consent,
    isConsentSaved,
    saveConsent,
    setShowConsentDialog,
    showConsentDialog,
    canShowCookieBanner,
    setCanShowCookieBanner,
  } = useContext(ConsentContext);

  const registerPageview = useCallback(
    (page: URL, ref?: URL) => {
      if (isConsentSaved && consent.analytics) {
        trackSiteImprovePageView(page, ref);
      }
    },
    [isConsentSaved, consent.analytics]
  );

  const registerAnalyticsEvent = useCallback(
    (event: AnalyticsEvent) => {
      if (isConsentSaved && consent.analytics) {
        trackSiteImproveEvent(event);
      }
    },
    [isConsentSaved, consent.analytics]
  );

  return {
    isConsentSaved,
    consent,
    saveConsentAndUpdateTracking: saveConsent,
    showConsentDialog,
    setShowConsentDialog,
    canShowCookieBanner,
    setCanShowCookieBanner,

    registerAnalyticsEvent,
    registerPageview,
  };
};

export { findCookie, getValueFromCookie };
