import statusPage, { StatusPagePage } from './statusPage';
import { TimeUnit, addDuration } from '../time/time';

export const STATUSPAGE_ROOT_URL = 'https://status.digipost.no/';

const signering_web_component_id = '9yxcrwwfqpcn';

const parseDateToHumanReadableFormat = function (date: Date) {
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  const hour = date.getHours().toString().padStart(2, '0');
  const minute = date.getMinutes().toString().padStart(2, '0');
  return `${day}.${month}.${year} ${hour}:${minute}`;
};

interface ServiceMessage {
  statusText: string;
  statusUrl: string;
  showServiceMessageIcon: boolean;
}

const getIncidentMessage = function (
  statuspage: StatusPagePage
): Promise<ServiceMessage | null> {
  return new Promise((resolve, reject) => {
    statuspage.incidents({
      filter: 'unresolved',
      success: function (data) {
        const incidents = data.incidents.filter(
          (i) =>
            i.components.filter(
              (c) =>
                c.id === signering_web_component_id &&
                c.status !== 'operational'
            ).length > 0
        );
        if (incidents.length === 1) {
          const { name, shortLink } = incidents[0];
          resolve({
            statusText: name,
            statusUrl: shortLink,
            showServiceMessageIcon: true,
          });
        } else if (incidents.length > 1) {
          resolve({
            statusText: 'Vi opplever for tiden driftsproblemer',
            statusUrl: STATUSPAGE_ROOT_URL,
            showServiceMessageIcon: true,
          });
        } else {
          resolve(null);
        }
      },
      error: function (error: Error) {
        console.error(error);
        reject('Error calling statuspage');
      },
    });
  });
};

const getUpcomingMaintenance = function (
  statuspage: StatusPagePage
): Promise<ServiceMessage | null> {
  return new Promise((resolve, reject) => {
    statuspage.scheduled_maintenances({
      filter: 'upcoming',
      success: function (data) {
        const maintenances = data.scheduled_maintenances.filter(function (sm) {
          const relevantComponents = sm.components.filter(function (c) {
            return c.id === signering_web_component_id;
          });
          if (relevantComponents.length) {
            const maintenanceDate = new Date(sm.scheduled_for);
            const maintenanceEndDate = new Date(sm.scheduled_until);
            const oneDayBeforeMaintenance = addDuration(
              TimeUnit.DAY,
              -1,
              maintenanceDate
            );
            const now = new Date();
            return oneDayBeforeMaintenance < now && now < maintenanceEndDate;
          }
          return false;
        });
        if (maintenances.length === 1) {
          const maintenance = maintenances[0];
          const maintenanceDate = parseDateToHumanReadableFormat(
            new Date(maintenance.scheduled_for)
          );
          const maintenanceEndDate = parseDateToHumanReadableFormat(
            new Date(maintenance.scheduled_until)
          );
          const { name, shortLink } = maintenance;
          const statusText = `${maintenanceDate} - ${maintenanceEndDate}: ${name}`;
          resolve({
            statusText: statusText,
            statusUrl: shortLink,
            showServiceMessageIcon: false,
          });
        } else if (maintenances.length > 1) {
          resolve({
            statusText:
              'Vi vil det neste døgnet gjennomføre planlagt vedlikehold',
            statusUrl: STATUSPAGE_ROOT_URL,
            showServiceMessageIcon: false,
          });
        } else {
          resolve(null);
        }
      },
      error: function (error: Error) {
        console.error(error);
        reject('Error calling statuspage');
      },
    });
  });
};

const getActiveMaintenance = function (
  statuspage: StatusPagePage
): Promise<ServiceMessage | null> {
  return new Promise((resolve, reject) => {
    statuspage.scheduled_maintenances({
      filter: 'active',
      success: function (data) {
        const maintenances = data.scheduled_maintenances.filter(function (sm) {
          return sm.components.some(function (c) {
            return c.id === signering_web_component_id;
          });
        });
        if (maintenances.length === 1) {
          const maintenance = maintenances[0];
          const maintencanceEndDate = parseDateToHumanReadableFormat(
            new Date(maintenance.scheduled_until)
          );
          const { name, shortLink } = maintenance;
          const statusText = `${name} - Vedlikehold forventes ferdig ${maintencanceEndDate}`;
          resolve({
            statusText: statusText,
            statusUrl: shortLink,
            showServiceMessageIcon: true,
          });
        } else if (maintenances.length > 1) {
          resolve({
            statusText: 'Vi gjennomfører planlagt vedlikehold',
            statusUrl: STATUSPAGE_ROOT_URL,
            showServiceMessageIcon: true,
          });
        } else {
          resolve(null);
        }
      },
      error: function (error) {
        console.error(error);
        reject('Error calling statuspage');
      },
    });
  });
};

const getServiceMessage = async function () {
  const statuspage = statusPage();
  const incident = await getIncidentMessage(statuspage);
  if (incident) {
    return incident;
  }
  const activeMaintenance = await getActiveMaintenance(statuspage);
  if (activeMaintenance) {
    return activeMaintenance;
  }
  const upcomingMaintenance = await getUpcomingMaintenance(statuspage);
  if (upcomingMaintenance) {
    return upcomingMaintenance;
  }
};

export default getServiceMessage;
