import { FunctionComponent, useEffect } from 'react';

import SpinnerHW from '@common/basics/spinner/SpinnerHW';
import {
  DownloadIcon,
  ExclamationTriangleIcon,
  IconColor,
  SuccessIcon,
} from '../icons';
import { addClassesFromProps } from '@common/basicFunctions';
import { sizes, widths, Button } from '.';
import { ButtonStyle, Size, Width } from './buttonAttributes';
import { useI18n } from '@common/language/i18n';
import { useTracking } from '@common/analytics/Analytics';
import { AppName } from '@common/analytics/appNames';
import { downloadDocument, useSigneringApi } from '@common/api/signeringApi';
import { ApiRequestStatus } from '@common/api/useApi';
import { App } from '../../apps';
import { linkToInternalSite } from '@common/navigation/externalNavigation';

import '../icons/icons.less';

export enum DocumentType {
  PAdES = 'PAdES',
  PDF = 'PDF',
}

interface DownloadDocumentButtonProps {
  downloadLink?: string;
  isPrimary?: boolean;
  text: string;
  width?: Width;
  size?: Size;
  buttonStyle?: ButtonStyle;
  dataTestid?: string;
  iconColor?: IconColor;
  extraClasses?: string;
  disabled?: boolean;
  documentType?: DocumentType;
  category: AppName;
  analyticsLabel?: string;
}

const DownloadDocumentButton: FunctionComponent<
  DownloadDocumentButtonProps
> = ({
  text,
  downloadLink,
  size,
  width,
  dataTestid,
  buttonStyle,
  iconColor = IconColor.WHITE,
  extraClasses,
  disabled,
  documentType = DocumentType.PAdES,
  category,
  analyticsLabel,
}) => {
  const [translation] = useI18n();
  const { registerAnalyticsEvent } = useTracking();
  const { refetch: download, request } = useSigneringApi({
    dataFetcher: downloadDocument,
    urlArgs: [downloadLink ? downloadLink : ''],
    options: {
      enabled: false,
      onSuccess: () => {
        registerAnalyticsEvent({
          category,
          action: `Lastet ned ${
            documentType === DocumentType.PAdES ? 'PAdES' : 'original PDF'
          }`,
          label: analyticsLabel,
        });
      },
    },
  });

  useEffect(() => {
    if (request.status === ApiRequestStatus.FAILURE) {
      registerAnalyticsEvent({
        category,
        action:
          documentType === DocumentType.PAdES
            ? `Feilmelding: Feilet å laste ned en PAdES${
                request.error.errorResponse
                  ? ' - ' + request.error.errorResponse.errorMessage
                  : ''
              } - url: ${downloadLink || 'ingen url funnet'}`
            : `Feilmelding: Feilet å laste ned en original PDF${
                request.error.errorResponse
                  ? ' - ' + request.error.errorResponse.errorMessage
                  : ''
              } - url: ${downloadLink || 'ingen url funnet'}`,
        label: 'Feilmelding vist',
      });
    }
  }, [request, category, downloadLink, registerAnalyticsEvent, documentType]);

  const commonProps = {
    size: size ? size : sizes.medium,
    buttonStyle: buttonStyle,
    width: width ? width : widths.normal,
    dataTestid: dataTestid,
    onClick: download,
    disabled,
  };

  return request.status === ApiRequestStatus.PENDING ? (
    <Button
      {...commonProps}
      extraClassName={`spinner-button${addClassesFromProps(extraClasses)}`}
      disabled={true}
      ariaLabel={text}
    >
      <SpinnerHW />
    </Button>
  ) : (
    <>
      <Button
        {...commonProps}
        extraClassName={extraClasses}
        disabled={!downloadLink || disabled}
      >
        <DownloadIcon
          color={iconColor}
          extraClassName="button-icon-with-text button-icon--middle"
        />
        {text}
      </Button>
      {request.status === ApiRequestStatus.SUCCESS && (
        <p className="hw-block--pt-small-2" aria-live="polite">
          <SuccessIcon extraClassName="icon-with-text" />
          {translation.common.signaturejob.download_document_success}
          {'. '}
          <a
            className="hw-link hw-link--small"
            href={linkToInternalSite('/hjelp/signere#nedlasting').href}
            onClick={() =>
              registerAnalyticsEvent({
                category: App.SIGNATURE_PORTAL,
                action: 'Klikket på link til hjelpeside',
                label: '/hjelp#nedlasting',
              })
            }
          >
            {translation.common.signaturejob.download_document_help_link}
          </a>{' '}
          {translation.common.signaturejob.download_document_help_text}
        </p>
      )}
      {request.status === ApiRequestStatus.FAILURE && (
        <>
          <p className="hw-block--pt-small-2" aria-live="polite">
            <ExclamationTriangleIcon extraClassName="icon-with-text" />
            {translation.common.signaturejob.download_document_failed_message}
          </p>
        </>
      )}
    </>
  );
};

export default DownloadDocumentButton;
