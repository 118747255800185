import { FunctionComponent, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useI18n } from '../language/i18n';
import { subsituteIdsWithPlacedholders } from '../navigation/urlSubstitutions';
import Heading from '../pageBuildingBlocks/Heading';
import { trackMessage } from './errorTracking';
import wonderingMan from './illustrations/Undrende.svg';

import './not-found.less';

const NotFound: FunctionComponent = () => {
  const [translation] = useI18n();
  const location = useLocation();

  useEffect(() => {
    trackMessage(
      `Not found page shown - ${subsituteIdsWithPlacedholders(
        location.pathname
      )}`
    );
  }, [location]);

  return (
    <section
      className="hw-container hw-container--pt hw-container--pb-medium-4"
      data-testid="not-found-page"
    >
      <div className="not-found-header-wrapper">
        <Heading headerText={translation.common.not_found.header} />
        <p className="not-found-status-code">
          {translation.common.not_found.status_code}
        </p>
      </div>
      <div className="hw-flex hw-block--mt-medium-3-responsive hw-block--mb-medium-3-responsive">
        <div className="hw-block hw-block--mt-medium-3-responsive">
          <p>{translation.common.not_found.description}</p>
          <p
            dangerouslySetInnerHTML={{
              __html: translation.common.not_found.restart_description,
            }}
          />
        </div>
        <img
          className="hw-block hw-block--mt-medium-3-responsive"
          src={wonderingMan}
          height="400"
          alt=""
        />
      </div>
    </section>
  );
};

export default NotFound;
