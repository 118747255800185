import { FunctionComponent } from 'react';

import { useI18n } from '../language/i18n';

import './skip-to-main.less';

const SkipToMain: FunctionComponent = () => {
  const [translation] = useI18n();

  return (
    <a className="skip-to-content hw-button hw-button--primary" href="#main">
      {translation.orgportal.menu.skip_to_main}
    </a>
  );
};

export default SkipToMain;
