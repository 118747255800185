import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName, library } from '@fortawesome/fontawesome-svg-core';
import { FunctionComponent } from 'react';

import { IconSize, IconColor, IconSet } from '.';
import { addClassesFromProps } from '@common/basicFunctions';

import {
  faFileUpload,
  faExclamationCircle,
  faCheckCircle,
  faTimes,
  faPlus,
  faMinus,
  faChevronDown,
  faChevronUp,
  faTimesCircle,
  faChevronRight,
  faChevronLeft,
  faClock,
  faEnvelope,
  faInfoCircle,
  faMobile,
  faPaperPlane,
  faArrowUp,
  faArrowDown,
  faBell,
  faQuestionCircle,
  faCogs,
  faDesktop,
  faExclamationTriangle,
  faCalendarAlt,
  faUser,
  faCommentAlt,
  faLink,
  faPaste,
  faStar,
  faArrowUpRightFromSquare,
  faPenToSquare,
  faGear,
  faGears,
  faBuilding,
  faSliders,
  faCopyright,
} from '@fortawesome/free-solid-svg-icons';

import {
  faTimes as falTimes,
  faPlus as falPlus,
  faMinus as falMinus,
  faStar as falStar,
  faPen as falPen,
  faEnvelope as falEnvelope,
  faChevronLeft as falChevronLeft,
  faCircle2 as falCircle2,
  faCircle3 as falCircle3,
} from '@fortawesome/pro-light-svg-icons';

import {
  faCommentsAlt as fasCommentsAlt,
  faPen as fasPen,
  faArrowAltToBottom as fasArrowAltToBottom,
  faCircle1 as fasCircle1,
  faCircle2 as fasCircle2,
  faCircle3 as fasCircle3,
  faBriefcaseBlank as fasBriefcaseBlank,
} from '@fortawesome/pro-solid-svg-icons';

import { faUser as farUser } from '@fortawesome/free-regular-svg-icons';

import {
  faSearch as farSearch,
  faChevronDown as farChevronDown,
  faLink as farLink,
  faTimes as farTimes,
  faChevronRight as farChevronRight,
  faChevronLeft as farChevronLeft,
  faGlobe as farGlobe,
} from '@fortawesome/pro-regular-svg-icons';

library.add(
  faFileUpload,
  faExclamationCircle,
  faCheckCircle,
  faTimes,
  faPlus,
  faMinus,
  faChevronDown,
  farChevronDown,
  faChevronUp,
  faChevronRight,
  faChevronLeft,
  faTimesCircle,
  faClock,
  faEnvelope,
  faInfoCircle,
  faMobile,
  faPaperPlane,
  farSearch,
  faUser,
  farUser,
  falTimes,
  falPlus,
  falMinus,
  falStar,
  faArrowUp,
  faArrowDown,
  faBell,
  faQuestionCircle,
  faCogs,
  faDesktop,
  fasPen,
  falPen,
  falEnvelope,
  faExclamationTriangle,
  faCalendarAlt,
  fasCommentsAlt,
  fasArrowAltToBottom,
  farLink,
  falChevronLeft,
  farTimes,
  faCommentAlt,
  faLink,
  faPaste,
  farChevronRight,
  farChevronLeft,
  faStar,
  faArrowUpRightFromSquare,
  faPenToSquare,
  fasCircle1,
  fasCircle2,
  falCircle2,
  fasCircle3,
  falCircle3,
  fasBriefcaseBlank,
  faGear,
  faGears,
  faCopyright,
  faBuilding,
  faSliders,
  farGlobe
);

const sizeClass = (size = IconSize.DEFAULT) => {
  switch (size) {
    case IconSize.SMALL:
      return 'sm';
    case IconSize.MEDIUM:
      return undefined;
    case IconSize.LARGE:
      return '2x';
    case IconSize.EXTRA_LARGE:
      return '3x';
    case IconSize.DEFAULT:
      return 'lg';
  }
};

const colorClass = (color = IconColor.BLACK) => {
  switch (color) {
    case IconColor.GREEN:
      return ' hw-color-green';
    case IconColor.YELLOW:
      return ' hw-color-yellow';
    case IconColor.PRIMARY:
      return ' hw-color-primary';
    case IconColor.PRIMARY_DARK:
      return ' hw-color-primary-dark';
    case IconColor.PRIMARY_DARKER:
      return ' hw-color-primary-darker';
    case IconColor.GRAY_DARKER:
      return ' hw-color-gray-darker';
    case IconColor.GRAY_LIGHT:
      return ' hw-color-gray-light';
    case IconColor.WHITE:
      return ' hw-color-white';
    case IconColor.BLACK:
      return ' hw-color-black';
  }
};

const setClass = (set = IconSet.SOLID) => {
  switch (set) {
    case IconSet.REGULAR:
      return 'far';
    case IconSet.LIGHT:
      return 'fal';
    case IconSet.SOLID:
      return 'fas';
  }
};

const FAIcon: FunctionComponent<FaIconProps> = ({
  icon,
  extraClassName,
  size,
  color,
  set,
  title,
}) => {
  return (
    <FontAwesomeIcon
      icon={[setClass(set), icon]}
      size={sizeClass(size)}
      className={`fa-fw ${colorClass(color)}${addClassesFromProps(
        extraClassName
      )}`}
      aria-hidden={title ? false : true}
      title={title}
    />
  );
};

export interface FaIconProps {
  icon: IconName;
  extraClassName?: string;
  size?: IconSize;
  color?: IconColor;
  set?: IconSet;
  title?: string;
}

export default FAIcon;
