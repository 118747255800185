import { FunctionComponent } from 'react';
import { BaseCheckboxProps } from '@hedwig/forms/Checkbox';
import '@hedwig/forms/checkbox.less';

const Checkbox: FunctionComponent<BaseCheckboxProps> = ({
  wrapperClassName,
  checked = false,
  onClick,
  label,
  ...readOnlyProps
}: BaseCheckboxProps) => (
  <div
    className={wrapperClassName}
    style={{ display: 'flex', justifyContent: 'space-between' }}
  >
    <p className="hw-grid__item hw-four-fifths hw-text-title">{label}</p>
    <label className="hw-checkbox">
      <input
        type="checkbox"
        onChange={
          !readOnlyProps.readonly
            ? () => readOnlyProps.onChange(!checked)
            : undefined
        }
        checked={checked}
        onClick={onClick}
        readOnly={readOnlyProps.readonly}
      />
      <i
        className={`hw-checkbox__indicator${
          readOnlyProps.readonly ? ' checkbox-disabled' : ''
        }`}
      />
    </label>
  </div>
);

export default Checkbox;
