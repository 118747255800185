import { FunctionComponent } from 'react';
import '@less/img.less';
import { addClassesFromProps } from '@common/basicFunctions';
import hourglass from '@images/icons/hour_glass.svg';

const HourGlassIcon: FunctionComponent<HourGlassIconProps> = ({
  extraClassName,
}) => {
  return (
    <img
      src={hourglass}
      aria-hidden="true"
      alt=""
      className={`hourglass__icon${addClassesFromProps(extraClassName)}`}
    />
  );
};

interface HourGlassIconProps {
  extraClassName?: string;
}

export default HourGlassIcon;
