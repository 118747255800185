import { FunctionComponent } from 'react';

import { useI18n } from '../language/i18n';
import { addClassesFromProps } from '../basicFunctions';
import { useTracking } from '../analytics/Analytics';
import { AppName } from '../analytics/appNames';
import { DIFI_HELP_URL } from './helpUrls';

interface HelpLinkProps {
  goToDifiHelp: boolean;
  openInNewTab?: boolean;
  linkToDefaultTopic?: string;
  className?: string;
  url: string;
  appName: AppName;
  dataTestid?: string;
}

const HelpLink: FunctionComponent<HelpLinkProps> = ({
  goToDifiHelp,
  openInNewTab = true,
  linkToDefaultTopic,
  className,
  url,
  appName,
  dataTestid,
}) => {
  const [translation] = useI18n();
  const { registerAnalyticsEvent } = useTracking();

  const props = openInNewTab
    ? { target: '_blank', rel: 'noopener noreferrer' }
    : {};
  return (
    <a
      href={
        goToDifiHelp
          ? DIFI_HELP_URL
          : `/${url}${linkToDefaultTopic ? `/${linkToDefaultTopic}` : ''}`
      }
      className={addClassesFromProps(className)}
      data-testid={dataTestid}
      {...props}
      onClick={() =>
        registerAnalyticsEvent({
          category: appName,
          action: 'Trykket på hjelp i header',
          label: goToDifiHelp
            ? 'Gikk til Digdir-hjelpeside'
            : 'Gikk til vår hjelpeside',
        })
      }
    >
      {translation.common.help_link}
    </a>
  );
};

export default HelpLink;
