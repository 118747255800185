import Button, { ButtonProps } from './Button';
import { addClassesFromProps } from '@common/basicFunctions';
import { buttonStyles } from './buttonAttributes';
import { FunctionComponent } from 'react';

const ButtonStyledAsLink: FunctionComponent<ButtonProps> = (props) => {
  return (
    <Button
      {...props}
      extraClassName={`buttonlink${addClassesFromProps(props.extraClassName)}`}
      buttonStyle={buttonStyles.transparent}
    />
  );
};

export default ButtonStyledAsLink;
