import { useMemo } from 'react';
import {
  MutationFunction,
  UseMutateFunction,
  useMutation,
  UseMutationOptions,
} from 'react-query';
import { ApiError } from './api';
import { ApiRequestStatus } from './useApi';
import { EnkelSigneringApiErrorResponse } from '../../privatePerson/api/enkelSigneringApi';

export type MutationRequest<T, ApiErrorResponse> =
  | {
      status: ApiRequestStatus.IDLE;
    }
  | {
      status: ApiRequestStatus.PENDING;
    }
  | {
      status: ApiRequestStatus.SUCCESS;
      result: T;
    }
  | {
      status: ApiRequestStatus.FAILURE;
      error: ApiError<ApiErrorResponse>;
    };

export type EnkelSigneringMutationRequest<T> = MutationRequest<
  T,
  EnkelSigneringApiErrorResponse
>;

export function useMutationApi<ApiErrorResponse, T, V>(
  apiCall: MutationFunction<T, V>,
  trackApiError: (error: ApiError<ApiErrorResponse>) => void,
  options: UseMutationOptions<T, ApiError<ApiErrorResponse>, V> = {}
): {
  request: MutationRequest<T, ApiErrorResponse>;
  mutate: UseMutateFunction<T, ApiError<ApiErrorResponse>, V, unknown>;
  reset: () => void;
} {
  const { isLoading, isSuccess, error, isIdle, mutate, data, reset } =
    useMutation<T, ApiError<ApiErrorResponse>, V>(
      apiCall.name,
      apiCall,
      options
    );
  const request: MutationRequest<T, ApiErrorResponse> = useMemo(() => {
    return isIdle
      ? {
          status: ApiRequestStatus.IDLE,
        }
      : isLoading
      ? {
          status: ApiRequestStatus.PENDING,
        }
      : isSuccess
      ? {
          status: ApiRequestStatus.SUCCESS,
          result: data,
        }
      : {
          status: ApiRequestStatus.FAILURE,
          error: error
            ? error
            : { name: 'ApiError', message: 'Unknown ApiError' },
        };
  }, [isIdle, isLoading, isSuccess, error, data]);

  if (request.status === ApiRequestStatus.FAILURE) {
    trackApiError(request.error);
  }

  return {
    request,
    mutate,
    reset,
  };
}
