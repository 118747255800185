import './spinner.less';

const SpinnerHW = () => {
  return (
    <div
      data-testid="spinner-hw"
      className="spinner"
      aria-label="Spinner"
      aria-busy="true"
    />
  );
};

export default SpinnerHW;
