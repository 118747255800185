import { useState, useEffect } from 'react';
import { ErrorIcon } from '@hedwig/icons';
import getServiceMessage, {
  STATUSPAGE_ROOT_URL,
} from '../statuspage/getServiceMessage';

const ServiceMessage = () => {
  const [serviceMessage, setServiceMessage] = useState<string>();
  const [serviceMessageUrl, setServiceMessageUrl] = useState<string>();
  const [showServiceMessageIcon, setShowServiceMessageIcon] =
    useState<boolean>();
  const [showServiceMessage, setShowServiceMessage] = useState(false);
  useEffect(() => {
    const serviceMessageCall = async () => {
      const statusPageResponse = await getServiceMessage();
      if (statusPageResponse) {
        setServiceMessage(statusPageResponse.statusText);
        setServiceMessageUrl(statusPageResponse.statusUrl);
        setShowServiceMessageIcon(statusPageResponse.showServiceMessageIcon);
        setShowServiceMessage(true);
      }
    };
    void serviceMessageCall();
  }, []);

  return showServiceMessage ? (
    <div className="header-warn-wrapper">
      {showServiceMessageIcon && (
        <ErrorIcon extraClassName="header-warn-icon" />
      )}
      <div className="header-warn-text">
        <span>
          {serviceMessage} -{' '}
          <a
            className="hw-link hw-link--small"
            href={serviceMessageUrl ? serviceMessageUrl : STATUSPAGE_ROOT_URL}
          >
            Les mer
          </a>
        </span>
      </div>
    </div>
  ) : null;
};

export default ServiceMessage;
