import {
  MutationFunction,
  UseMutateFunction,
  UseMutationOptions,
} from 'react-query';
import {
  DecoderFunction,
  decodeType,
  record,
  string,
} from 'typescript-json-decoder';

import {
  ApiError,
  del as genericDel,
  delWithResponse as genericDelWithResponse,
  get as genericGet,
  getLocation as genericGetLocation,
  post as genericPost,
  postForm as genericPostForm,
  postFormWithoutResponse as genericPostFormWithoutResponse,
  postWithoutResponse as genericPostWithoutResponse,
  put as genericPut,
  patch as genericPatch,
  downloadDocument as genericDownloadDocument,
} from './api';
import { ApiRequest, useApi } from './useApi';
import { ApiCommand } from './apiCommand';
import { MutationRequest, useMutationApi } from './useMutationApi';
import { errorWhitelist, trackError } from '../errorHandling/errorTracking';
import { DataFetcher, createDataFetcher } from './dataFetcher';

type SigneringApiErrorResponse = decodeType<
  typeof signeringApiErrorResponseDecoder
>;
const signeringApiErrorResponseDecoder = record({
  errorCode: string,
  errorMessage: string,
});

export type SigneringApiError = ApiError<SigneringApiErrorResponse>;

export type SigneringApiRequest<T> = ApiRequest<T, SigneringApiErrorResponse>;

type SigneringApiCommand<
  T,
  V extends unknown[],
  E extends unknown[]
> = ApiCommand<SigneringApiErrorResponse, T, V, E>;

function isFunction<V extends unknown[]>(
  url: string | ((...args: V) => string)
): url is (...args: V) => string {
  return typeof url === 'function';
}

export function createSigneringApiDataFetcher<
  T,
  V extends unknown[],
  E extends unknown[]
>(command: {
  decoder: DecoderFunction<T>;
  url: string | ((...args: V) => string);
  timeout?: number;
  name: string;
  trackError?: (error: SigneringApiError) => void;
}): DataFetcher<SigneringApiErrorResponse, T, V, E> {
  return createDataFetcher({
    url: isFunction(command.url) ? command.url : () => command.url as string,
    decoder: command.decoder,
    timeout: command.timeout,
    apiErrorDecoder: signeringApiErrorResponseDecoder,
    name: command.name,
    trackError: command.trackError,
  });
}

export function useSigneringApi<T, V extends unknown[], E extends unknown[]>(
  apiCommand:
    | SigneringApiCommand<T, V, E>
    | DataFetcher<SigneringApiErrorResponse, T, V, E>
): {
  request: SigneringApiRequest<T>;
  refetch: () => Promise<void>;
  isRefetching: boolean;
} {
  return useApi(apiCommand);
}

export function useSigneringMutationApi<T, V>(
  apiCall: MutationFunction<T, V>,
  options: UseMutationOptions<T, SigneringApiError, V> = {}
): {
  request: MutationRequest<T, SigneringApiErrorResponse>;
  mutate: UseMutateFunction<T, SigneringApiError, V, unknown>;
  reset: () => void;
} {
  const handleSigneringMutationError = (error: SigneringApiError) => {
    if (
      !error.errorResponse ||
      !errorWhitelist.includes(error.errorResponse.errorCode)
    ) {
      trackError(
        new Error(
          `ApiCall '${apiCall.name}' failed with error message '${error.message}'`,
          { cause: error }
        )
      );
    }
  };
  return useMutationApi<SigneringApiErrorResponse, T, V>(
    apiCall,
    handleSigneringMutationError,
    options
  );
}

export async function get<T>(
  url: string,
  decoder: DecoderFunction<T>,
  timeout?: number
): Promise<T> {
  return genericGet(signeringApiErrorResponseDecoder, url, decoder, timeout);
}

export async function getLocation(
  url: string,
  headers?: HeadersInit,
  timeout?: number
): Promise<string> {
  return genericGetLocation(
    signeringApiErrorResponseDecoder,
    url,
    headers,
    timeout
  );
}

export async function put(
  url: string,
  body?: BodyInit,
  headers?: HeadersInit
): Promise<void> {
  return genericPut(signeringApiErrorResponseDecoder, url, body, headers);
}

export async function post<T>(
  url: string,
  body: BodyInit,
  decoder: DecoderFunction<T>,
  headers: HeadersInit = {}
): Promise<T> {
  return genericPost(
    signeringApiErrorResponseDecoder,
    url,
    decoder,
    body,
    headers
  );
}
export async function postWithoutResponse(
  url: string,
  body?: BodyInit
): Promise<void> {
  return genericPostWithoutResponse(
    signeringApiErrorResponseDecoder,
    url,
    body
  );
}

export async function postFormWithoutResponse(
  url: string,
  form: FormData
): Promise<void> {
  return genericPostFormWithoutResponse(
    signeringApiErrorResponseDecoder,
    url,
    form
  );
}

export async function postFormWithResponse<T>(
  url: string,
  decoder: DecoderFunction<T>,
  form: FormData
): Promise<T> {
  return genericPostForm(signeringApiErrorResponseDecoder, url, decoder, form);
}

export async function del(url: string): Promise<void> {
  return genericDel(signeringApiErrorResponseDecoder, url);
}

export async function delWithResponse<T>(
  url: string,
  decoder: DecoderFunction<T>
): Promise<T> {
  return genericDelWithResponse(signeringApiErrorResponseDecoder, url, decoder);
}

export async function patch(url: string, body: BodyInit): Promise<void> {
  return genericPatch(signeringApiErrorResponseDecoder, url, body);
}

export const downloadDocument = createDataFetcher({
  name: 'DownloadDocument',
  apiCaller: (url: string) =>
    genericDownloadDocument(signeringApiErrorResponseDecoder, url),
  url: (url: string) => url,
});
