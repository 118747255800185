import { FunctionComponent } from 'react';

import { Button } from '.';
import { ButtonStyle, Size, Width } from './buttonAttributes';
import { useI18n } from '@common/language/i18n';

interface LogOutButtonProps {
  extraClassName?: string;
  buttonStyle?: ButtonStyle;
  size?: Size;
  width?: Width;
}

const LogOutButton: FunctionComponent<LogOutButtonProps> = ({
  extraClassName,
  buttonStyle,
  size,
  width,
}) => {
  const [translation] = useI18n();

  return (
    <Button
      extraClassName={extraClassName}
      size={size}
      width={width}
      buttonStyle={buttonStyle}
      onClick={(e) => {
        e.preventDefault();
        window.location.href = '/web/logout';
      }}
    >
      {translation.logout.button}
    </Button>
  );
};

export default LogOutButton;
